<template>
  <div>

    <v-container fluid>
      <v-chip :color="correctColorPosition(dealerInfo.position)"
        class="dealer-info px-4"> {{ dealerInfo.position == 'pr' ? "Supervisor" : dealerInfo.position }} </v-chip>

        <v-row class="pt-5">
        <p class="dealer-name pl-2">{{ dealerInfo && dealerInfo.name ? "Name: " + dealerInfo.name : '---' }}</p>
        <p class="dealer-name">{{ dealerInfo && dealerInfo.last_login ? "Last Login: " + moment(dealerInfo.last_login).format('DD/MM/YYYY hh:mm') : '---'}}</p>
        <p class="dealer-name">{{ dealerInfo && dealerInfo.barcode ? 'Id: ' + dealerInfo.barcode : 'id: ---'}}</p>
        <v-btn @click="_openDialogScanDealer(true)" :loading="loadingDealerButton" :disabled="loadingDealerButton"
          color="blue-grey" class="white--text dealer-changer">
          Change Dealer
          <v-icon right dark>
            mdi-cloud-sync
          </v-icon>
        </v-btn>
      </v-row>
      <v-row>
        <v-col cols="5" class="live-channel-info-container">
          <iframe v-if="channel && channel.live_urls" :src="channel.live_urls[0]" frameborder="0"
            width="100%" height="415px" scrolling="no" style="padding:0px; scroll"></iframe>
        </v-col>
        <v-col cols="2" class="game-control-container">
          <p
            :class="socketChannelCountdown && socketChannelCountdown <= 10 ? 'red--text uppercase default-count-down' : 'uppercase default-count-down'">
            {{ socketChannelCountdown ? socketChannelCountdown : defaultCountDown }}
          </p>
          
          <p :class="[
            channelStatus(channel.is_open).color,
            'uppercase channel-status',
          ]">
            {{ channelStatus(channel.is_open).status }}
          </p>

          <div style="width:100%; text-align:center;">
            <v-btn v-if="(dealerInfo && dealerInfo.position == 'dealer')" :dark="darkmode" style="width:150px" color="primary mr-2" @click="_selectGame" :disabled=" (channel.is_open == false || socketChannelCountdown) ? true: false ">
                <v-icon class="mr-2" v-if="channel.is_open == false || socketChannelCountdown ? true: false">fas fa-circle-notch fa-spin</v-icon> Start Game
            </v-btn>
            <br> <br>
            <v-btn v-if="(dealerInfo && dealerInfo.position == 'dealer')" :dark="darkmode" style="width:150px" color="warning" @click="_saveApongResult" :disabled="channel.is_open == true || channel.is_open == null ? true: false ">
              Set Result
            </v-btn>
            <br>
            <br>
            <!-- <v-btn v-if="channel.is_ready" style="width:150px">
              បើកចាន
            </v-btn> -->
            <v-btn v-if="(dealerInfo && dealerInfo.position == 'pr' || dealerInfo.position == 'pitbox')" color="error"
              class="mt-5" style="width:150px" @click="_clearResult" :disabled="
                loading == true ||
                  channel.is_open == true
                  ? true
                  : false || channel.is_open == null
                  ? true
                  : false
              ">
              <v-icon class="mr-2" v-if="loading">fas fa-circle-notch fa-spin</v-icon>
              Clear Result
            </v-btn>
            <br>
            <br>
            <v-btn v-if="(dealerInfo && dealerInfo.position == 'pr' || dealerInfo.position == 'pitbox')" color="error"
              class="mt-5" style="width:150px" @click="alertResetResultDialog" :disabled="
                (channel.is_open == false || socketChannelCountdown) ? true: false 
              ">
              <v-icon class="mr-2" v-if="loading">fas fa-circle-notch fa-spin</v-icon>
              Reset Result
            </v-btn>

            <!-- <v-btn v-if="(dealerInfo && dealerInfo.position == 'pr' || dealerInfo.position == 'pitbox')" 
            style="width:150px" color="error m-5" @click="alertResetResultDialog" class="text-right" :disabled="(channel.is_open == false || socketChannelCountdown) ? true: false ">
              Reset Result
            </v-btn> -->

            <!-- <v-col v-if="channel.is_ready" cols="3" class="text-center text-h5 berk-chan-container">
            <span class="berk-chan">
              បើកចាន
            </span>
          </v-col> -->

          </div>
          
        </v-col>
        <v-col cols="5">
          <!-- <v-col cols="12">
            <v-text-field :autofocus="true" type="number" 
            class="black--text text-h4 font-weight-bold"
              style="font-weight: bolder !important;"
             ref='search' autocomplete="off" v-model="scanCard" onpaste="return false">
            </v-text-field>
          </v-col> -->
          <v-col cols="12">
            <v-text-field :autofocus="true" type="number" :disabled="!dealerInfo._id || !allowInput || getAllowScanCard" 
            class="black--text text-h4 font-weight-bold"
              style="font-weight: bolder !important;"
             ref='search' autocomplete="off" v-model="scanCard" onpaste="return false">
            </v-text-field>
          </v-col>
          <v-row style="width: 100%;">
              <v-col cols="12" class="player-card-container">
              <v-img
                max-height="300"
                max-width="300"
                :src="'images/abong/' + imageUrl"
              ></v-img>
            </v-col> 
          </v-row>
          <!-- <div style="margin-top:80px;text-align: right;">
            <v-btn color="primary" :dark="darkmode" @click="update" :disabled="channel.is_open == false || loading == true ? true : false ">
               <v-icon class="mr-2" v-if="loading">fas fa-circle-notch fa-spin</v-icon> Update
            </v-btn>
          </div> -->


          <!-- <div v-if="channel.is_last_game">
            <div v-if="dealerInfo && dealerInfo.position == 'dealer'" style="margin-top:100px; text-align: right;">
              <v-btn class="update-chanel-btn" @click="_finishedCard"
                :color="channelUpdateStatus(channel.is_open).background"
                :disabled="(channel.is_first_game == true || channel.is_open == false)">
                Finish
              </v-btn>
            </div>
          </div>
          <div v-else>
            <div v-if="dealerInfo && dealerInfo.position == 'dealer'" style="margin-top:100px;text-align: right;">
              <v-btn class="update-chanel-btn" :color="channelUpdateStatus(channel.is_open).background"
                @click="_updateChanelStatus"
                :disabled="channel.is_open == false || !canUpdateStatus || Boolean(socketChannelCountdown && socketChannelCountdown > 2)">
                {{ channelUpdateStatus(channel.is_open).status }}
              </v-btn>
            </div>
          </div>         -->
        </v-col>

      </v-row>
    </v-container>
    <!-- <v-form ref="form" v-model="valid" lazy-validation>
      <v-container fluid>
        <v-row>
          <v-col cols="6">
            <template>
              <v-select
                :items="[true, false]"
                v-model="channel.enable"
                label="Enable"
              />
            </template>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="channel.fight_no"
              label="Fight Number"
              readonly
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="channel.info"
              label="Channel Information"
              :rules="rules"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="channel.video_info"
              label="Video Information"
              :rules="rules"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" class="text-left">
            <v-btn :dark="darkmode" color="primary mr-2" @click="_selectGame" :disabled=" channel.is_open == false || socketChannelCountdown ? true: false ">
                <v-icon class="mr-2" v-if="channel.is_open == false || socketChannelCountdown ? true: false">fas fa-circle-notch fa-spin</v-icon> Start Game
            </v-btn>
          </v-col>
           <v-col cols="2" class="text-left">

            <v-btn :dark="darkmode" color="warning" @click="_select" :disabled="channel.is_open == true || (socketChannelCountdown <=5 && socketChannelCountdown >0 ) || channel.is_open == null ? true: false ">
              Set Result
            </v-btn>
          </v-col>
           <v-col v-if="channel.is_ready" cols="3" class="text-center text-h5 berk-chan-container">
            <span class="berk-chan">
              បើកចាន
            </span>
          </v-col>
          <v-col v-if="!channel.is_ready" cols="3" class="text-center text-h5 count-number-container">
            <span :class="socketChannelCountdown && socketChannelCountdown<=5 ? 'red--text uppercase default-count-down' :'uppercase default-count-down'">
              {{socketChannelCountdown ? socketChannelCountdown : defaultCountDown }}
            </span>
          </v-col>
          <v-col cols="3" class="text-center text-h5">
            <span class="uppercase">
              Channel is
            </span>
            <span
              :class="[
                channelStatus(channel.is_open).color,
                'uppercase pl-4 channel-status'
              ]"
              
              >
               {{channelStatus(channel.is_open).status }}</span
            >
          </v-col>
          <v-col cols="2" class="text-right">
            <v-btn color="primary" :dark="darkmode" @click="update" :disabled="channel.is_open == false || loading == true ? true : false ">
               <v-icon class="mr-2" v-if="loading">fas fa-circle-notch fa-spin</v-icon> Update
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form> -->

    <v-dialog v-model="dialogResult" width="700" height="600">
      <v-card class="mx-auto my-3">
        <v-row>
          <v-col
            cols="6"
            sm="6"
            style="text-align:center; border:1px solid #000000; background-color:#ffffff"
          >
            <v-btn
              depressed
              color="white"
              height="80"
              width="150"
              @click="_submitResult(objSnNumber[0])"
            >
              <v-img height="60" width="60" src="images/1number.png"></v-img>
            </v-btn>
          </v-col>
          <v-col
            cols="6"
            sm="6"
            style="text-align:center; border:1px solid #000000; background-color:#ffffff"
          >
            <v-btn
              depressed
              color="white"
              height="80"
              width="150"
              @click="_submitResult(objSnNumber[5])"
            >
              <v-img height="60" width="60" src="images/6number.png"></v-img>
            </v-btn>
          </v-col>
          <v-col
            cols="3"
            sm="3"
            style="text-align:center; border:1px solid #000000; background-color:#ffffff"
          >
            <v-btn
              depressed
              color="white"
              height="50"
              width="65"
              @click="_submitResult(objSnNumber[1])"
            >
              <v-img height="50" width="65" src="images/2number.png"></v-img>
            </v-btn>
          </v-col>
          <v-col
            cols="3"
            sm="3"
            style="text-align:center; border:1px solid #000000; background-color:#ffffff"
          >
            <v-btn
              depressed
              color="white"
              height="50"
              width="65"
              @click="_submitResult(objSnNumber[2])"
            >
              <v-img height="50" width="65" src="images/3number.png"></v-img>
            </v-btn>
          </v-col>
          <v-col
            cols="3"
            sm="3"
            style="text-align:center; border:1px solid #000000; background-color:#ffffff"
          >
            <v-btn
              depressed
              color="white"
              height="50"
              width="65"
              @click="_submitResult(objSnNumber[3])"
            >
              <v-img height="50" width="65" src="images/4number.png"></v-img>
            </v-btn>
          </v-col>
          <v-col
            cols="3"
            sm="3"
            style="text-align:center; border:1px solid #000000; background-color:#ffffff"
          >
            <v-btn
              depressed
              color="white"
              height="50"
              width="65"
              @click="_submitResult(objSnNumber[4])"
            >
              <v-img height="50" width="65" src="images/5number.png"></v-img>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="confirmDialog"
      persistent
      max-width="390"
      max-height="320"
    >
      <v-card style="height:250px">
        <v-card-title class="justify-center res-img-containter">
          <v-img
            class="result-image"
            :src="'images/' + resultData.order + 'number.png'"
          ></v-img>
        </v-card-title>
        <v-card-actions>
          <v-btn color="pink ml-4 confirm-btn" @click="_cancelResult">
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1 mr-4 confirm-btn" @click="_saveResult" :disabled="loadingResult == true ? true: false " >
             <v-icon class="mr-2" v-if="loadingResult">fas fa-circle-notch fa-spin</v-icon>
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="getDealerDialog" width="420" height="420" persistent>
      <v-card class="text-center px-5">
        <p class="text-h5 text-danger">Please Scan Card</p>
        <v-text-field autofocus autocomplete="off" label="" type="password" v-model="barcodeDealer" onpaste="return false"></v-text-field>
      </v-card>
   </v-dialog>

   <v-dialog v-model="dialogResetResult" persistent max-width="480" max-height="380">
      <v-card class="text-center">
        <v-card-title class="text-h6 justify-center my-5">
          Do you want to Reset Result ?
        </v-card-title>
        <v-card-actions>
          <v-btn color="grey darken-1" class="cancel-btn" @click="dialogResetResult = false">
            No, Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn class="agree-btn" @click="_resetResult">
            Yes, Reset Result
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
addEventListener('keypress', (event) => {
  if (event.code == 'NumpadEnter') window.callInside._selectGame();
  if (event.code == 'NumpadDivide') window.callInside.reloadWindow();
  if (event.code == 'NumpadMultiply') window.callInside.focusSearch();
  if (event.code == 'NumpadAdd') window.callInside._openDialogScanDealer(true);
  if (event.code == 'NumpadSubtract') window.callInside._saveApongResult();
  // if (event.code == 'Backquote') window.callInside.increaseColumn();

  
});
window.onkeydown = function (e) {
  return !(e.keyCode == 32 && e.target == document.body);
};
import { mapGetters, mapMutations } from "vuex";
import moment from "moment";

export default {
  props: ["channel", "objSnNumber" ,"socketChannelType", "socketChannelCountdown","getDealerDialog","dealerInfo","imageUrl","getAllowScanCard"],

  data() {
    return {
      valid: true,
      rules: [value => !!value || "Required."],
      dialogResult: false,
      confirmDialog: false,
      resultData: [],
      n1: null,
      updateChanel: [],
      defaultCountDown: 0,
      barcodeDealer: "",
      loadingDealerButton: false,
      dialogResetResult: false,
      scanCard: '',
      allowInput : true,
    };
  },
  computed: {
    ...mapGetters("$_modules", {
      darkmode: "getDarkMode",
    }),
    ...mapGetters("$_apongChannel", {
      loading: "getLoading",
      loadingResult:"getLoadingResult"
    })
  },
  watch: {
    imageUrl: function (newV) {
    if (newV) this.scanCard = '';
  },
    socketChannelType: function () {

    },
    socketChannelCountdown: function (newV) {
      if(newV == 0){
        this.$nextTick(() => this.$refs.search.focus())
      }
    },
    loading: function () {
    },
    loadingResult: function () {
    },
    dealerInfo: function () {
      this.$nextTick(() => this.$refs.search.focus())
    },
    getDealerDialog: function (newV) {
      if (newV == false) {
        this.$nextTick(() => this.$refs.search.focus())
      }
    },
    barcodeDealer: function (newV) {
      if (newV.length == 8) {
        this._submitDealerBarcode(newV);
      }
      this.$nextTick(() => this.$refs.search.focus());
    },
    scanCard: function (newV) {
      if (newV.length == 4) {
        this.scanCard = '';
        this.$nextTick(() => this.$refs.search.focus());
        this._submitCardcode(newV);
        this.imageUrl = '';
      }
      if (newV.length > 4) {
        this.scanCard = '';
      }
      if (newV == 2888) {
        this.allowInput = false;
        setTimeout(() => {
          this.allowInput = true;
          this.$nextTick(() => this.$refs.search.focus());
        }, 1200);
        this.scanCard = '';
      }
    },
  },
  async created() {
    await this.initialDealer();
  },
  async mounted() {
    window.callInside = this;
    this.$nextTick(() => this.$refs.search.focus())
  },

  methods: {
    async initialDealer() {
      let dealerId = this.$cookies.get("dealerId");
      if (dealerId) {
        await this._submitDealerBarcode(dealerId.barcode)
      }
      else this._openDialogScanDealer(true);
    },
    moment,
    alertResetResultDialog() {
      this.dialogResetResult = true;
    },
    update() {
      if (this.$refs.form.validate()) {
        this.$emit("submitUpdateChanel", this.channel);
      }
    },

    channelStatus(status) {
      if (status === true) {
        return {
          status: "Open",
          color: "green--text"
        };
      } else if (status === false) {
        return {
          status: "Close",
          color: "red--text"
        };
      } else {
        return {
          status: "LIVE",
          color: "warning--text"
        };
      }
    },

    _select() {
      // this.dialogResult = true;
    },
    reloadWindow(){
      window.location.reload();
    },
    focusSearch(){
      this.$nextTick(() => this.$refs.search.focus());
    },

    _selectGame() {
      this.$emit("clearImageUrl", ''); 
      this.$socket.emit("server-start-count-down", { status: "start" });
        
    },

    _cancelResult() {
      this.confirmDialog = false;
    },

    _submitResult(i) {
      this.resultData = i;
      this.confirmDialog = true;
    },
    _submitCardcode(cardCode) {
      // if(this.dealerInfo && this.dealerInfo.position == 'dealer')   this.$emit("submitCardCode", cardCode);
      if (!this.getAllowScanCard) {
      if (this.dealerInfo && this.dealerInfo.position == 'dealer') {
        if (this.channel.is_open == false ) {
          if(!cardCode == ''){
            this.$emit("submitCardCode", cardCode);   
            this.allowInput = false;  
            setTimeout( ()=> {
                  this.allowInput = true;
                  this.$nextTick(() => this.$refs.search.focus());
                },1200);
          }
        }
      }
      }else{
        this.$toastr.e("Waiting for previous card return back")
      }
      this.scanCard = '';
    },
    async _clearResult() {
      if (this.dealerInfo && this.dealerInfo.position == 'pr' || this.dealerInfo.position == 'pitbox')
        if (this.channel.is_open == false || this.channel.is_open == null) {
          this.$emit("submitClearResult", this.resultData);
          this.$nextTick(() => this.$refs.search.focus());
        }
        this.scanCard = '';
        this.$nextTick(() => this.$refs.search.focus());
    },

    _openDialogScanDealer(payload) {
      this.barcodeDealer = '';
      this.$emit("popUpDealer", payload);
      this.barcodeDealer = '';
    },
    _submitDealerBarcode(barcode) {
      this.$emit("submitBarcodeDealer", barcode);
      this.barcodeDealer = '';
      this.$nextTick(() => this.$refs.search.focus());
    },

    correctColorPosition(position) {
      switch (position) {
        case 'dealer':
          return 'error';
        case 'pr':
          return 'primary';
        case 'pitbox':
          return 'success';
        default:
          break;

      }
    },

    async _saveResult() {
      this.$emit("submitResult", this.resultData);
      this.confirmDialog = false;
      this.dialogResult = false;
    },
    async _saveApongResult() {
      this.$emit("submitApongResult", this.channel);
      this.confirmDialog = false;
      this.dialogResult = false;
    },
    _resetResult() {
      this.$emit("submitResetResult");
      this.dialogResetResult = false;
    },
    ...mapMutations("$_apongChannel", ["LOADING"]),
  }
};
</script>

<style scoped>

.player-card-container {
  height: 300px;
  margin-left: 120px;
}

.live-channel-info-container {
  width: 100%;
  height: 440px;
  border: 1px solid white;
  border-radius: 3px;
  position: relative;
  margin-top: 50px;
}

.dealer-changer {
  margin-top: -5px;
  margin-left: 5px;
  margin-bottom: 0px
}

.dealer-name {
  font-size: 18px;
  text-align: center;
  font-weight: 700;
  margin-bottom: -40px;
  padding-left: 5px;
}
.dealer-info {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 0px;
  margin-left: -7px;
  text-transform: uppercase;

}
/* .channel-status {
  font-weight: 700;
  font-size: 28px;
  padding-top: 3px;
} */
.channel-status {
  font-weight: 900;
  font-size: 40px;
  padding-top: 0px;
  text-shadow: 1px 1px whitesmoke;
  text-align: center;
  margin-top: 60px;
}
.result-image {
  max-width: fit-content;
  max-height: 100%;

  display: block;

  object-fit: contain;
}
.res-img-containter {
  width: 385;
  height: 180px;
  justify-content: center;
}
.confirm-btn{
  color: white !important; font-weight:700
}
/* .default-count-down{
  font-size:120px!important;
  letter-spacing: 9px!important;
  font-weight: 900;
} */
.default-count-down {
  font-size: 120px !important;
  letter-spacing: 9px !important;
  font-weight: 900;
  text-align: center;
  margin-top: 80px;
}
.count-number-container{
  justify-content: center;
  margin-top:30px;
}
.berk-chan-container{
  justify-content: center;
  margin-top:0px;
}
.berk-chan{
  font-family: fantasy;
    color: rgb(67, 153, 105);
    font-weight: 800;
    text-shadow: 2px 1px azure;
    font-size: 60px;

}
</style>
