<template>
    <v-flex class="pt-15" style="padding-top: 500px !important;">
        <div id="wrapper">
            <div class="auth-page login-wrapper">
                <div class="login-panel-mobile position-relative">
                    <!-- <div class="login-header py-0 my-0">
                        <img :src="formLogo" class="d-block mx-auto login-header-img w-50">
                    </div> -->
                    <!-- <h6 class="text-center auth-via">Login via</h6> -->
                    <v-form ref="form" v-model="validate" lazy-validation @submit.prevent="login"
                        class="form-container pt-5">
                        <div v-if="isOTPShow == true" class="otp-layout">
                            <div class="otp-label">INPUT OTP CODE</div>
                            <v-otp-input class="justify-content-center" ref="otpInput" inputClasses="otp-input"
                                :numInputs="6" :is-input-num="true" separator="-" :shouldAutoFocus="true"
                                @on-complete="handleOnComplete" @on-change="handleOnChange" />
                            <div v-if="isOTPError" class="otp-error">{{ otpErrorMgs }}</div>
                        </div>
                        <div v-else-if="isOTPShow == false">
                           
                            <div class="card card-primary">
                            <div class="card-body">
                                <v-form ref="form" lazy-validation @submit.prevent="login" style="font-size: 50px; font-weight: bold;">
                                    <div style="width:100%; display: flex; justify-content: center;">
                                        <img src="/images/logo.png" 
                                        class="my-2 mx-auto justify-center align-center" 
                                        style="width:120px; height:120px"/>
                                    </div>
                                    
                                    <hr class="my-5 bg-gray-400 opacity-50" />
                                    <div v-if="error" class="mb-4 text-danger text-center">
                                        {{ description }}
                                    </div>
                                
                                        <div class="form-group">
                      <label class="font-style">User name</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <div class="input-group-text" style="padding: 30px;">
                            <i class="fas fa-user font-style"></i>
                          </div>
                        </div>
                        <input type="text" class="form-control phone-number font-style" v-model="user.user_name" label="Username" :rules="rules" required
                                        placeholder="Enter your username" @input="error = false" style="padding: 30px;">
                      </div>
                    </div>
                                    <div class="form-group">
                                        <label class="font-style">Password Strength</label>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <div class="input-group-text" style="padding: 30px;">
                                                    <i class="fas fa-lock font-style"></i>
                                                </div>
                                            </div>
                                            <input type="password" class="form-control pwstrength font-style"
                                                data-indicator="pwindicator" v-model="user.password"  label="Password" :rules="rules"
                                        required  placeholder="Enter your password"
                                        @input="error = false" style="padding: 30px;">
                                        </div>
                                        <div id="pwindicator" class="pwindicator">
                                            <div class="bar"></div>
                                            <div class="label"></div>
                                        </div>
                                    </div>
                                    <div class="flex justify-end mt-3">
                                        <v-btn :loading="loading" type="submit" :disabled="loading" color="primary"
                                            style="background-color: #6975E2; width: 100%; margin: 0 !important; padding: 30px; font-size: 30px;">
                                            Login
                                        </v-btn>
                                    </div>
                                </v-form>
                            </div>
                        </div>
                        </div>
                    </v-form>
                    <!-- <div class="lang-switcher-wr my-6" :style="isOTPShow ? 'display:none' : ''">
                        {{ $t('title.changeLanguage') }}:
                        <div>
                            <div role="group" aria-label="Language Switcher"
                                class="btn-group btn-group-toggle lang-switcher">
                                <a href="#" data-language="en" class="lang-item btn btn-sm  lang-eng"
                                    :class="passLanguage == 'english' ? 'active' : ''"
                                    @click="_changeLanguage('english')">
                                    <img src="/images/flag-en.webp" alt="english" style="margin:auto;  padding-bottom: 2px;">English</a>
                                <a href="#" data-language="cn" class="lang-item btn btn-sm lang-cn"
                                    :class="passLanguage == 'khmer' ? 'active' : ''" @click="_changeLanguage('khmer')">
                                    <img src="/images/flag-kh.png" alt="khmer" style="margin:auto;  padding-bottom: 2px;"> ខ្មែរ</a>
                                <a href="#" data-language="ph" class="lang-item btn btn-sm lang-ph"
                                    :class="passLanguage == 'thai' ? 'active' : ''" @click="_changeLanguage('thai')">
                                    <img src="/images/flag-th.jpg" alt="thai" style="margin:auto; padding-bottom: 2px;"> แบบไทย
                                </a>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </v-flex>

</template> 
  
<script>
import "@/assets/css/login.css";
import { mapActions } from "vuex";
export default {
    props: ['passUser', 'passValid', 'passLoading', 'passLanguage'],
    data() {
        return {
            rules: {
                required: [(value) => !!value || 'Required.'],
                min: v => (v && 0 !== v.length >= 6) || 'Min 6 characters',
                emailMatch: () => (`The email and password you entered don't match`),
            },
            validate: false,
            formLogo: '/images/logo.png',
            isShowText: false,
            user: {
                user_name: null,
                password: null,
                app_id: null
            },
            loading: false,
            error: false,
            isOTPShow: false,
            tmpChannelUserId: null,
            show: false,
            isOTPError: false,
            otpErrorMgs: "Wrong OTP",
            tempChannelUser: null,
            description: ""
        }
    },
    methods: {
        reset() {
            this.$refs.form.reset();
        },
        async handleOnComplete(value) {
            try {
                let otpData = {
                    user_id: this.tmpChannelUserId,
                    otp: value
                }
                const response = await this.fetchVerifyOtp(otpData)
                if (response.data.code) {
                    //success
                    this.$cookies.set("token", response.data.data.token);
                    this.$cookies.set("channel-type", 1);
                    this.$cookies.set("local", 'kh');
                    this.$cookies.set("user", this.tempChannelUser);
                    this.$router.push({ path: "/dashboard" });
                } else {
                    this.$refs.otpInput.clearInput();
                    this.otpErrorMgs = response.data.message.descriptions;
                    this.isOTPError = true;
                }

            } catch (error) {
                this.$refs.otpInput.clearInput();
                this.otpErrorMgs = error.message;
                this.isOTPError = true;
            }
        },
        handleOnChange() {
            this.isOTPError = false;
        },
        async login() {
            if (this.$refs.form.validate()) {
                try {
                    this.loading = true;
                    this.user.app_id = process.env.VUE_APP_APP_ID;
                    console.log("app-id", this.user.app_id);
                    const response = await this.fetchLogIn(this.user);
                    if (response.data.code
                        //condition dnt let user role player login in to admin system
                        && response.data.data.user.role != "61829df5e64b1b1768832700"
                        && response.data.data.user.role != "61829dfee64b1b1768832709"
                        && response.data.data.user.role != "61829e05e64b1b1768832712") {
                        const { token, uuid, user } = response.data.data;

                        if (token == "") {
                            this.$cookies.set("uuid", uuid);
                            this.tempChannelUser = user
                            this.tmpChannelUserId = user._id
                            this.isOTPShow = true
                        } else {
                            let rolee = user.role
                            this.$cookies.set("token", token);
                            this.$cookies.set("user_role", rolee);
                            this.$cookies.set("channel-type", 1);
                            this.$cookies.set("local", 'kh');
                            this.$cookies.set("user", user);
                            if(response.data.data.user.role == "6191dc644825e6f57ffa231f" || response.data.data.user.role == "6191dce94825e65937fa2340" || response.data.data.user.role == "6424080f50b77c6cf890ab11" || response.data.data.user.role == "6472bfce1ba4b8a1604940c2"){
                                this.$router.push({ path: "/userSubAgent" });
                            }else{
                                this.$router.push({ path: "/users" });
                            }
                        }

                    } else {
                        this.error = true;
                        this.description = response.data.message.descriptions;
                    }
                } catch (error) {
                    this.error = true;
                    return error;
                } finally {
                    this.loading = false;
                }
            }
        },
        ...mapActions("$_auth", ["fetchLogIn", "fetchVerifyOtp"]),
        _changeLanguage(language) {
            this.$emit("changeLanguage", language);
        }
    }
}
</script>
<style>
.theme--light.v-input,
.theme--light.v-input input,
.theme--light.v-input textarea {
    color: rgba(0, 0, 0, .87) !important;
}

.otp-input {
    width: 60px !important;
}

.otp-layout {
    position: absolute !important;
}
.font-style {
    font-size: 30px !important;
}
</style>