export default {
    search: 'Search',
    permission_name: 'Permission Name',
    role_name: 'Role Name',
    menu_name: 'Menu Name',
    description: 'Description',
    order: 'Order',
    route_name: 'Route Name',
    url_name: 'URL Name',
    icon: 'Icon',
    parent: 'Parent',
    permission: 'Permission',
    visible: 'Visible',
    user_name: 'Username',
    bank_account: 'Bank Account',
    user_role: 'User Role',
    password: 'Password',
    total_bet_a_fight: 'កំណត់ការឈ្នះក្នុង១ដៃធំបំផុត',
    total_bet_win_a_day:'កំណត់ការឈ្នះក្នុង១ថ្ងៃធំបំផុត',
    user:"Select User",
    default_bet_a_fight:"Default Bet a Fight",
    default_bet_win_a_day:"Default Bet a Day",
    agent_bet_a_fight:"Set Limit to Agent for their Player In a Fight",
    agent_bet_win_a_day:"Set Limit to Agent for their Player In a Day",
    target :  "Target",
    percentage_meron :  "Percentage of Meron",
    percentage_wala :  "Percenntage of Wala",
    meron_name : "Name of Meron",
    wala_name : "Name of Wala",
    start_time :  "Start Time",
    end_time :  "End Time",
    meron : "Meron",
    child_role_balance: "Banlance of Child",
    child_role_balance_d: "Banlance of Child USD",
    child_role_balance_b: "Banlance of Child Baht",
    child_role_balance_r: "Banlance of Child RIEL",
    child_role_name: "Name Of Child",
    chose_type: "Choose Currency",
    bet_win_a_day: "Total Win a Day",
    bet_win_a_Fight: "Total Win a Match",
    enable:"Enable",
    listAllCompany:"Select Company",
    channel_type:"Select channel Type",
    startDate:"Start Date",
    endDate:"End Date",
    percentage_wala_min:"Min Wala(%)",
    percentage_wala_max:"Max Wala(%)",
    percentage_meron_min:"Min Meron(%)",
    percentage_meron_max:"Max Meron(%)",
    target_kh:"Target KH",
    target_usd:"target Usd",
    target_tb: "target TB",
    fight_no : "Fight no",
    percentage_wala_start:"Start Wala(%)",
    percentage_meron_start :"Start Meron(%)",
    amount_max:"Amount Max",
    amount_min:"Amount Min",
    listAllSenior:"Select Senior",
    percentage_vip:"Decrease Percentage",
    suspend:"Suspend",
    channel1 :"CO1",
    channel2 :"CO2",
    channel3 :"CO3",
    channel4 :"CO4",
    channel5 :"KL1",
    channel6 :"BAC1",
    channel7 :"BAC2",
    channel18 :"BAC3",
    channel8 :"TD1",
    channel9 :"TD2",
    channel19 :"TD3",
    channel10 :"LO1",
    channel11 :"YU1",
    channel12 :"AP1",
    channel13 :"FT1",
    channel14 :"TS1",
    channel15 :"BA4",
    channel16 :"Roulette",
    channel17 :"AP2",
    channel20 :"HAN2",
    channel21 :"HAN3",
    main_link: "Main Link",
    list_company: "List Company",
    api_player_link: "Selecet API Player link",
    api_admin_link: "Selecet API Admin link",
    list_company_d: "Selecet Company Dollar",
    list_senior_d: "Selecet Senior Dollar",
    list_master_d: "Selecet Master Dollar",
    list_agent_d: "Selecet Agent Dollar",
    list_company_r: "Selecet Company Riel",
    list_senior_r: "Selecet Senior Riel",
    list_master_r: "Selecet Master Riel",
    list_agent_r: "Selecet Agent Riel",
    list_company_b: "Selecet Company Baht",
    list_senior_b: "Selecet Senior Baht",
    list_master_b: "Selecet Master Baht",
    list_agent_b: "Selecet Agent Baht",
    app_name: "App Name",
    is_base: "Is Base",
    barcode: "Barcode",
    dealer_name: "Username",
    dealer_dob: "Date of birth",
    profile_image: "Profile Name",
    maintenance_id: "APP ID",
    is_maintenance: "Maintenance Status",
    betting_frequency: "កំណត់ចំនួនដងនៃការចាក់",
    total_bet_win_a_day_lotto: "Maximum win a day limit for Lotto (ឈ្នះធំបំផុតក្នុង១ថ្ងៃសម្រាប់ឡូតូ)",
    total_bet_win_a_day_yuki: "Maximum win a day limit for Yuki (ឈ្នះធំបំផុតក្នុង១ថ្ងៃសម្រាប់យូគី)",
    total_bet_win_a_day_pp78: "Maximum win a day limit for PP78 (ឈ្នះធំបំផុតក្នុង១ថ្ងៃសម្រាប់ឆ្នោត)",
    
};
