const UPDATE_CONFIG_USER_DATA = (state, data) => {
    state.configUserData = data;
}

const UPDATE_CONFIG_SLOT_LIMIT_DATA = (state, data) => {
    state.configSlotLimitData = data;
}

const UPDATE_CHANNEL_AFTER_RESULT = (state, data) => {
    state.currentChannel = data;
}
const LOADING = (state, data) => {
    state.loading = data;
}

const UPDATE_USERS = (state, data) => {
    state.users = data;
};

const UPDATE_CURRENT_DATA = (state, data) => {
   state.currentConfigUserData = data

};

const UPDATE_POPUP = (state, data) => {
    if (!data) {
        state.currentConfigUserData = {
            user_name: null,
            total_bet_a_fight: null,
            total_bet_win_a_day: null,
            // balance: null,
            user_id: null
        }
    }
    state.popUp = data;
};

const DELETE_ITEMS = (state, data) => {
    state.deleteItems = data
};
const UPDATE_CONFIG_USER_BY_ID = (state, data) => {
    state.userConfigData = data
};

export default {
    UPDATE_CONFIG_USER_DATA,
    UPDATE_USERS,
    UPDATE_CHANNEL_AFTER_RESULT,
    LOADING,
    UPDATE_POPUP,
    UPDATE_CURRENT_DATA,
    DELETE_ITEMS,
    UPDATE_CONFIG_USER_BY_ID,
    UPDATE_CONFIG_SLOT_LIMIT_DATA
};