<template>
  <v-btn :value="value" class="py-10 px-5 btn-height" active-class="btnn-active ">
    <div :class="['cir', color]"></div>
  </v-btn>
</template>

<script>
export default {
  props: ['color', 'value'],
  name: "btn-result"
}
</script>

<style scoped>
.btn-height{
  height: 110px!important;
}
.cir {
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 55px;
  width: 55px;
  max-width: 65px;
  max-height: 65px;
  border-radius: 100px;
  margin: 3px 3px 2px 2px;
}

.cir-blue {
  border: 50px solid #0b4592;
}

.cir-red {
  border: 50px solid #b31013;
}

.cir-green {
  border: 50px solid #28a745;
}

.cir-cancel {
  border: 50px solid grey;
}
.btnn-active {
  background-color: #faa845 !important;
}

</style>
