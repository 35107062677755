<template>
  <v-card>
    <form-channel :getAllowScanCard="getAllowScanCard" :channel="currentChannel.currentChannel"
      :summaryBanker="summaryBanker" :summaryPlayer="summaryPlayer" :summaryTie="summaryTie"
      :objCoTypeOfBetting="objCoTypeOfBetting" :socketChannelType="dataFromSocket.type"
      :socketChannelCountdown="dataFromSocket.countDown" :selectResult="selectResult" :dealerInfo="getDealerInfo"
      v-on:submitBarcodeDealer="handleScanBarcodeDealer" :totalBanker="totalBanker" :totalPlayer="totalPlayer"
      :csBanker1="csBanker1" :csPlayer1="csPlayer1" :getDealerDialog="getDealerDialog" :getResultEvent="getResultEvent"
      v-on:submitFinishedCard="handleFinishedCard" v-on:submitUpdateStatus="handleUpdateChanelStatus"
      v-on:submitUpdateChanel="handleSubmitUpdateChanel" v-on:submitClearCard="handleClearCard"
      v-on:submitVoidShoe="handleVoidShoe" v-on:submitResetResult="handleResetResult"
      v-on:submitResetShoe="handleResetShoe" v-on:submitDynamicRes="handleDynamicRes"
      v-on:submitCardCode="handleSubmitCardCode" v-on:popUpDealer="handlePopupDealer" />

    <table-result v-on:submitResult="handleSubmitResult" :channel="currentChannel.currentChannel"
      :objCoTypeOfBetting="objCoTypeOfBetting" :objCoResult="objCoResult" :loadingResult="loadingResult"
      :objSummaryCoResult="objSummaryCoResult" v-on:submitDynamicRes="handleDynamicRes" />
  </v-card>
</template>

<script>


import formChannel from "./_components/formChannel";
import tableResult from "./_components/tableResult";
import { mapGetters, mapActions, mapMutations } from "vuex";
import Vue from 'vue';

export default {
  components: {
    formChannel,
    tableResult,
  },
  data: () => {
    return {
      dataFromSocket: [],
      totalBanker: 0,
      totalPlayer: 0,
      selectResult: {
        order: null,
        _id: null,
      },
      loading: false,
      dialogResult: false,
      csBanker1: "",
      csBanker2: "",
      csBanker3: "",
      csPlayer1: "",
      csPlayer2: "",
      csPlayer3: "",
      summaryBanker:0,
      summaryPlayer:0,
      summaryTie:0,

    };
  },
  computed: {

    ...mapGetters("$_modules", {
      darkmode: "getDarkMode",
    }),
    ...mapGetters("$_dragonChannel", {
      currentChannel: "getCurrentChannel",
      objCoTypeOfBetting: "getBtnResults",
      objSummaryCoResult: "getSummaryResult",
      objCoResult: "getResultTable",
      objKdPayout: "getKdPayout",
      loadingResult: "getLoadingResult",
      getLastResult: "getLastResult",
      getSkipColumn: "getSkipColumn",
      getSkipRow: "getSkipRow",
      getSkipBetween: "getSkipBetween",
      getDealerInfo: "getDealerInfo",
      getDealerDialog: "getDealerDialog",
      getDialogResult: "getdialogResult",
      getResultEvent: "getResultEvent",
      getCurrentCard: "getCurrentCard",
      getAllowScanCard: "getAllowScanCard",
      getCurrentTotal: "getCurrentTotal"
    }),
  },

  async created() {
    await this.initialData().then(() => {
      let getData = this.getCurrentCard;
      let totalB = 0, totalP = 0;
      if (getData.tiger.length > 0) {
        for (let i in getData.tiger) {
          if (getData.tiger[i].scan_number == 1) this.csBanker1 = getData.tiger[i].image;
          totalB = getData.tiger[i].value;
        }
        this.totalBanker = totalB;
      } else {
        this.csPlayer1 = 'false';
        this.totalPlayer = 0;
      }
      if (getData.dragon.length > 0) {
        for (let j in getData.dragon) {
          if (getData.dragon[j].scan_number == 1) this.csPlayer1 = getData.dragon[j].image;

          totalP = getData.dragon[j].value;
        }
        this.totalPlayer = totalP;
      } else {
        this.csPlayer1 = 'false';
        this.totalPlayer = 0;
      }
    });
  },

  watch: {
    getResultEvent: function (newV) {
      if (newV == true) {
        this.csBanker1 = "";
        this.csPlayer1 = "";
        this.totalBanker = 0;
        this.totalPlayer = 0;
        this.summaryBanker = 0;
        this.summaryPlayer = 0;
        this.summaryTie = 0;
      }
    },
    objCoResult: function () { },
    loadingResult: function () { },
  },
  async mounted() {
    if (localStorage.getItem('reloaded')) {
        // The page was just reloaded. Clear the value from local storage
        // so that it will reload the next time this page is visited.
        localStorage.removeItem('reloaded');
    } else {
        // Set a flag so that we know not to reload the page twice.
        localStorage.setItem('reloaded', '1');
        location.reload();
    }
    
    await this.fetchCurrentCard();
    this.$socket.on("client-start-count-down", data => {
      if (data) {
        this.dataFromSocket = data;
        if (this.dataFromSocket.countDown == 5) {
          this.fetchCurrentChannel();
          this.getTotalAmount();
        }
        if (this.dataFromSocket.countDown == 10) {
          this.getTotalAmount();
        }
      }
    });
    this.$el.addEventListener('click', this.onClick);
  },
  destroyed() {
    this.$el.removeEventListener('click', this.onClick);
  },
  methods: {
    async getTotalAmount() {   
      let channel_type = Vue.$cookies.get('channel-type');
      await this.$request
        .get({
          url: "outstandingByChannel/"+ channel_type,
        })
        .then((res) => {
          if (res.data.code) {
            // console.log("res.data.data",res.data.data.object);
            let dataObject = res.data.data.object;
            for(let i in dataObject){
              if(dataObject[i].betting_type == "tiger"){
                this.summaryBanker = dataObject[i].total_amount_by_usd;
              }else if(dataObject[i].betting_type == "dragon"){
                this.summaryPlayer = dataObject[i].total_amount_by_usd;
              }else if(dataObject[i].betting_type == "tie"){
                this.summaryTie = dataObject[i].total_amount_by_usd;
              }
            }
          } 
        });
    },
    async initialData() {
      await this.fetchUserAccessPermission(this.$route.path);
      if (this.checkUserPermission("VIEW")) {
        await this.fetchCurrentChannel();
        await this.fetchBtnResult();
        await this.fetchCurrentTotal();
      }
    },
    async getScanCard() {
      await this.fetchCurrentCard();
      let getData = this.getCurrentCard;
      let totalB = 0, totalP = 0;
      if (getData.tiger.length > 0) {
        for (let i in getData.tiger) {
          if (getData.tiger[i].scan_number == 1) this.csBanker1 = getData.tiger[i].image;
          totalB = getData.tiger[i].value;
        }
        this.totalBanker = totalB;
      } else {
        this.csBanker1 = 'false';
        this.totalBanker = 0;
      }
      if (getData.dragon.length > 0) {
        for (let j in getData.dragon) {
          if (getData.dragon[j].scan_number == 1) this.csPlayer1 = getData.dragon[j].image;

          totalP = getData.dragon[j].value;
        }
        this.totalPlayer = totalP;
      } else {
        this.csPlayer1 = 'false';
        this.totalPlayer = 0;
      }
    },
    doSingleValue(str) {
      let objStr = str.toString();
      return parseInt(objStr.substring(objStr.length - 1));
    },
    async handleResetResult() {
      await this.resetResult();
    },
    async handleResetShoe() {
      await this.resetShoe();
    },
    async handleVoidShoe() {
      let submitData = {
        channel_id: this.currentChannel.currentChannel._id,
        co_type_of_betting_id: "635a3646a9b6a9cbc6206d7c"
      };

      await this.voidShoe({ vm: this, data: submitData });
      this.csBanker1 = 'false';
      this.totalBanker = 0;
      this.summaryBanker = 0;
      this.summaryPlayer = 0;
      this.summaryTie = 0;
    },
    async handleScanBarcodeDealer(barcode) {
      let dealerId = this.$cookies.get("dealerId");

      let submitData;
      if (dealerId) {
        submitData = {
          'barcode': barcode,
          'old_barcode': dealerId.barcode,
          'channel_id': this.currentChannel.currentChannel._id,
        }
      } else {
        submitData = {
          'barcode': barcode,
          'old_barcode': '',
          'channel_id': this.currentChannel.currentChannel._id,
        }
      }

      await this._fetchGetDealerInfo({ vm: this, data: submitData });

    },
    async handleSubmitCardCode(cardCode) {
      let submitData = {
        'dealer_id': this.getDealerInfo._id,
        'channel_id': this.currentChannel.currentChannel._id,
        'shoe': this.currentChannel.currentChannel.shoe,
        'barcode': cardCode,
        'fight_no': this.currentChannel.currentChannel.fight_no,
        'group_no': this.currentChannel.currentChannel.group_no
      }
      await this.submitCardCode({ vm: this, data: submitData });
      await this.getScanCard();
    },
    async handleClearCard() {
      await this.UpdateClearCard({ vm: this, data: {} });
      this.csBanker1 = "";
      this.csPlayer1 = "";
      this.totalBanker = 0;
      this.totalPlayer = 0;
      await this.fetchCurrentChannel();
    },
    async handleUpdateChanelStatus() {
      let submitData;
      if (this.objKdPayout) {
        submitData = {
          _id: this.currentChannel.currentChannel._id,
          meron_percentage: parseInt(this.objKdPayout.meron_percentage),
          wala_percentage: parseInt(this.objKdPayout.wala_percentage),
        };
      } else {
        submitData = {
          _id: this.currentChannel.currentChannel._id,
          'dealer_id': this.getDealerInfo._id,
        };
      }
      await this.UpdateChanelStatus({ vm: this, data: submitData });
    },
    async handleFinishedCard() {
      let submitData;
      if (this.objKdPayout) {
        submitData = {
          _id: this.currentChannel.currentChannel._id,
          meron_percentage: parseInt(this.objKdPayout.meron_percentage),
          wala_percentage: parseInt(this.objKdPayout.wala_percentage),
        };
      } else {
        submitData = {
          _id: this.currentChannel.currentChannel._id,
          'dealer_id': this.getDealerInfo._id,
        };
      }
      await this.UpdateFinishedCard({ vm: this, data: submitData });
      await this.getScanCard();
    },
    async handleSubmitResult(data) {
      let submitData = {
        channel_id: this.currentChannel.currentChannel._id,
        co_type_of_betting_id: data.resultTypeId,
        pair_banker: data.pairBanker,
        pair_player: data.pairPlayer,
      };
      let getLastData = {
        group_no: this.currentChannel.currentChannel.group_no,
        shoe: this.currentChannel.currentChannel.shoe,
        channel_type: parseInt(this.$cookies.get('channel-type')),
      };
      await this._fetchLastResult(getLastData);

      let submitNewData = await this._defineColumnRow(
        this.getLastResult,
        submitData
      );
      await this.createResult({ vm: this, data: submitNewData });
    },

    async _defineColumnRow(oldData, newData) {
      // check if old and new has the same submit type
      if (
        oldData.type_id == newData.co_type_of_betting_id ||
        newData.co_type_of_betting_id == "615d5a609e9ab44fe23eba42" ||
        newData.co_type_of_betting_id == "635a3646a9b6a9cbc6206d7c"
      ) {
        // check if old cid colum is smaller than 5
        if (oldData.rid < 5) {
          if (oldData.isNewTable == false) {
            let data = {
              cid: oldData.cid,
              rid: oldData.rid,
              group_no: this.currentChannel.currentChannel.group_no,
              shoe: this.currentChannel.currentChannel.shoe,
              channel_type: parseInt(this.$cookies.get('channel-type')),
            };
            await this._fetchCheckRowExist(data);
            if (this.getSkipRow) {
              newData.cid = oldData.cid + 1;
              newData.rid = oldData.rid;
            } else {
              await this._fetchCheckBetweenExist(data);
              if (this.getSkipBetween) {
                newData.cid = oldData.cid + 1;
                newData.rid = oldData.rid;
              } else {
                newData.cid = oldData.cid;
                newData.rid = oldData.rid + 1;
              }
            }
          } else if (oldData.isNewTable == true) {
            newData.cid = 1;
            newData.rid = 1;
          }
        } else {
          newData.cid = oldData.cid + 1;
          newData.rid = oldData.rid;
        }
      } else {
        let data = {
          cid: oldData.cid + 1,
          rid: 1,
          group_no: this.currentChannel.currentChannel.group_no,
          shoe: this.currentChannel.currentChannel.shoe,
          channel_type: parseInt(this.$cookies.get('channel-type')),
        };
        await this._fetchCheckColumExist(data);
        if (this.getSkipColumn && this.getSkipColumn.cid) {
          newData.cid = this.getSkipColumn.cid + 1;
          newData.rid = 1;
        } else {
          newData.cid = 1;
          newData.rid = 1;
        }
      }
      return newData;
    },

    async handleSubmitUpdateChanel(dataUpdateChanel) {
      delete dataUpdateChanel.enable;
      await this.updateChannel({ vm: this, data: dataUpdateChanel });
    },

    handleDynamicRes(payload) {
      this._popUpDialogResult(payload);
    },
    handlePopupDealer(payload) {
      this._popUpDialogDealer(payload);
    },
    ...mapMutations("$_dragonChannel", [
      "UPDATE_CHANNEL",
      "SET_RESULT",
      "SUMMARY_RESULT",
      "RESULT_TABLE",
      "LOADINGRESULT",
      "UPDATE_KDPAYOUT",
      "GET_SKIP_COLUMN",
      "GET_SKIP_ROW",
      "GET_SKIP_BETWEEN",
      "UPDATE_DEALER_INFO",
      "POPUP_DIALOG_SCAN_DEALER",
      "RESULT_EVENT",
      "CURRENT_CARD",
      "ALLOW_SCAN",
      "CURRENT_TOTAL",
    ]),
    ...mapActions("$_dragonChannel", [
      "fetchCurrentChannel",
      "fetchBtnResult",
      "createResult",
      "voidShoe",
      "updateChannel",
      "UpdateChanelStatus",
      "UpdateFinishedCard",
      "UpdateClearCard",
      "resetResult",
      "resetShoe",
      "_popUpDialogResult",
      "_fetchLastResult",
      "_fetchCheckColumExist",
      "_fetchCheckRowExist",
      "_fetchCheckBetweenExist",
      "_fetchGetDealerInfo",
      "submitCardCode",
      "_popUpDialogDealer",
      "fetchCurrentCard",
      "fetchCurrentTotal"
    ]),


  },
};
</script>

<style lang="scss" scoped>

</style>
