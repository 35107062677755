<template>
  <div>
    <div class="section-header" hidden>
      <!-- <h1>Report OutStanding</h1> -->
      <div class="section-header-breadcrumb">
        <div class="breadcrumb-item active"><a href="/">Dashboard</a></div>
        <div class="breadcrumb-item">Report OutStanding</div>
      </div>
    </div>
    <div class="section-body">
    <div class="w-100 text-right" style="margin-top: 40px;">
      <div class="w-100 text-right">
        <v-btn
          class="white--text mr-4 mt-3"
          color="green darken-1"
          depressed
          @click="searchData"
        >
          <!-- @click="fetchListStatement(params)" -->
          Refresh
        </v-btn>
      </div>
    </div>

    <!--    Display List Front Page-->
    <v-row style="margin-top:50px" elevation="24">
      <v-col cols="12" md="12" sm="12" class="my-5 w-100 text-center">
        <span class="text-h5">Channel:</span> <span class="channel_number_style"> 11 </span>
        <v-btn 
        v-if="this.user.role_id == '61bf5eb0c73d7eea8634fbf4' || 
          this.user.role_id == '61d9a2a869e14b72d45f25d0'|| 
          this.user.role_id == '640d886e4f7dfc5b74997ee5'|| 
          this.user.role_id == '640d888d4f7dfc5b74997ee6'|| 
          this.user.role_id == '640d889e4f7dfc5b74997ee7'"
          :class="
            ch[0] && ch[0].enable ? 'success mb-3 ml-3' : 'error mb-3 ml-3'
          "
          @click="_updateChanel(ch[0], 11)"
          >{{
            ch[0] && ch[0].enable
              ? "Enabled"
              : ch[0] && !ch[0].enable
              ? "Disbaled"
              : "Loading...."
          }}</v-btn>
        <br />
        fight no:
        <span class="fino">{{ ch[0] ? ch[0].fight_no : "Loading" }}</span>
        <v-data-table :headers="headers1" :items="StatementObj" :server-items-length="3" item-key="currency"
          class="elevation-1 table-style"
          mobile-breakpoint="0" hide-default-header hide-default-footer>
          <template v-slot:header="{ props }">
            <template v-for="(headers1, i1) in [processTableHeaders(props.headers)]">
              <tr :key="i1">
                <th><span>Currency</span></th>
                <th><span class="num-1">ជូត</span></th>
                <th><span class="num-2">ឆ្លូវ</span></th>
                <th><span class="num-3">ខាល</span></th>
                <th><span class="num-4">ថោះ</span></th>
                <th><span class="num-5">រោង</span></th>
                <th><span class="num-6">ម្សាញ់</span></th>
                <th><span class="num-6">មមី</span></th>
                <th><span class="num-6">មមែ</span></th>
                <th><span class="num-6">វក</span></th>
                <th><span class="num-6">រកា</span></th>
                <th><span class="num-6">ច</span></th>
                <th><span class="num-6">កុរ</span></th>
                <th><span class="num-6">ស្តើង</span></th>
                <th><span class="num-6">ក្រាស់</span></th>
                <th><span class="num-6">ខៀវ</span></th>
                <th><span class="num-6">ក្រហម</span></th>
                <!-- <th><span class="num-6">គូរ</span></th>
                <th><span class="num-6">សេស</span></th>
                <th><span class="num-6">1-2-3</span></th>
                <th><span class="num-6">4-5-6</span></th>
                <th><span class="num-6">7-8-9</span></th>
                <th><span class="num-6">10-11-12</span></th> -->
              </tr>
            </template></template>
          <template v-slot:[`item`]="{ item }">
            <tr v-if="item">

              <td class="text-center">
                {{ item.currency }}
              </td>
              <td class="text-center win-color  text-decoration-underline row-pointer" @click="_goToDetail(item.rat_1)">
                {{
                    item.rat_1.currency_type == 1
                      ? currencyFormatEn(item.rat_1.amount)
                      : currencyFormatKm(item.rat_1.amount)
                }}
              </td>
              <td class="text-center win-color  text-decoration-underline row-pointer" @click="_goToDetail(item.ox_1)">
                {{
                    item.ox_1.currency_type == 1
                      ? currencyFormatEn(item.ox_1.amount)
                      : currencyFormatKm(item.ox_1.amount)
                }}
              </td>
              <td class="text-center win-color text-decoration-underline row-pointer" @click="_goToDetail(item.tiger_1)">
                {{
                    item.tiger_1.currency_type == 1
                      ? currencyFormatEn(item.tiger_1.amount)
                      : currencyFormatKm(item.tiger_1.amount)
                }}
              </td>
              <td class="text-center win-color text-decoration-underline row-pointer" @click="_goToDetail(item.rabbit_1)">
                {{
                    item.rabbit_1.currency_type == 1
                      ? currencyFormatEn(item.rabbit_1.amount)
                      : currencyFormatKm(item.rabbit_1.amount)
                }}
              </td>
              <td class="text-center win-color text-decoration-underline row-pointer" @click="_goToDetail(item.dragon_1)">
                {{
                    item.dragon_1.currency_type == 1
                      ? currencyFormatEn(item.dragon_1.amount)
                      : currencyFormatKm(item.dragon_1.amount)
                }}
              </td>
              <td class="text-center win-color text-decoration-underline row-pointer" @click="_goToDetail(item.snake_1)">
                {{
                    item.snake_1.currency_type == 1
                      ? currencyFormatEn(item.snake_1.amount)
                      : currencyFormatKm(item.snake_1.amount)
                }}
              </td>
              <td class="text-center win-color text-decoration-underline row-pointer" @click="_goToDetail(item.horse_1)">
                {{
                    item.horse_1.currency_type == 1
                      ? currencyFormatEn(item.horse_1.amount)
                      : currencyFormatKm(item.horse_1.amount)
                }}
              </td>
              <td class="text-center win-color text-decoration-underline row-pointer" @click="_goToDetail(item.goat_1)">
                {{
                    item.goat_1.currency_type == 1
                      ? currencyFormatEn(item.goat_1.amount)
                      : currencyFormatKm(item.goat_1.amount)
                }}
              </td>
              <td class="text-center win-color text-decoration-underline row-pointer" @click="_goToDetail(item.monkey_1)">
                {{
                    item.monkey_1.currency_type == 1
                      ? currencyFormatEn(item.monkey_1.amount)
                      : currencyFormatKm(item.monkey_1.amount)
                }}
              </td>
              <td class="text-center win-color text-decoration-underline row-pointer" @click="_goToDetail(item.rooster_1)">
                {{
                    item.rooster_1.currency_type == 1
                      ? currencyFormatEn(item.rooster_1.amount)
                      : currencyFormatKm(item.rooster_1.amount)
                }}
              </td>
              <td class="text-center win-color text-decoration-underline row-pointer" @click="_goToDetail(item.dog_1)">
                {{
                    item.dog_1.currency_type == 1
                      ? currencyFormatEn(item.dog_1.amount)
                      : currencyFormatKm(item.dog_1.amount)
                }}
              </td>
              <td class="text-center win-color text-decoration-underline row-pointer" @click="_goToDetail(item.pig_1)">
                {{
                    item.pig_1.currency_type == 1
                      ? currencyFormatEn(item.pig_1.amount)
                      : currencyFormatKm(item.pig_1.amount)
                }}
              </td>
              <td class="text-center win-color text-decoration-underline row-pointer" @click="_goToDetail(item.thin_1)">
                {{
                    item.thin_1.currency_type == 1
                      ? currencyFormatEn(item.thin_1.amount)
                      : currencyFormatKm(item.thin_1.amount)
                }}
              </td>
              <td class="text-center win-color text-decoration-underline row-pointer" @click="_goToDetail(item.thick_1)">
                {{
                    item.thick_1.currency_type == 1
                      ? currencyFormatEn(item.thick_1.amount)
                      : currencyFormatKm(item.thick_1.amount)
                }}
              </td>
              <td class="text-center win-color text-decoration-underline row-pointer" @click="_goToDetail(item.blue_1)">
                {{
                    item.blue_1.currency_type == 1
                      ? currencyFormatEn(item.blue_1.amount)
                      : currencyFormatKm(item.blue_1.amount)
                }}
              </td>
              <td class="text-center win-color text-decoration-underline row-pointer" @click="_goToDetail(item.red_1)">
                {{
                    item.red_1.currency_type == 1
                      ? currencyFormatEn(item.red_1.amount)
                      : currencyFormatKm(item.red_1.amount)
                }}
              </td>
              <!-- <td class="text-center win-color text-decoration-underline row-pointer" @click="_goToDetail(item.even_1)">
                {{
                    item.even_1.currency_type == 1
                      ? currencyFormatEn(item.even_1.amount)
                      : currencyFormatKm(item.even_1.amount)
                }}
              </td>
              <td class="text-center win-color text-decoration-underline row-pointer" @click="_goToDetail(item.odd_1)">
                {{
                    item.odd_1.currency_type == 1
                      ? currencyFormatEn(item.odd_1.amount)
                      : currencyFormatKm(item.odd_1.amount)
                }}
              </td> -->
              <!-- <td class="text-center win-color text-decoration-underline row-pointer" @click="_goToDetail(item.o_t_t_1)">
                {{
                    item.o_t_t_1.currency_type == 1
                      ? currencyFormatEn(item.o_t_t_1.amount)
                      : currencyFormatKm(item.o_t_t_1.amount)
                }}
              </td>
              <td class="text-center win-color text-decoration-underline row-pointer" @click="_goToDetail(item.f_f_s_1)">
                {{
                    item.f_f_s_1.currency_type == 1
                      ? currencyFormatEn(item.f_f_s_1.amount)
                      : currencyFormatKm(item.f_f_s_1.amount)
                }}
              </td>
              <td class="text-center win-color text-decoration-underline row-pointer" @click="_goToDetail(item.s_e_n_1)">
                {{
                    item.s_e_n_1.currency_type == 1
                      ? currencyFormatEn(item.s_e_n_1.amount)
                      : currencyFormatKm(item.s_e_n_1.amount)
                }}
              </td>
              <td class="text-center win-color text-decoration-underline row-pointer" @click="_goToDetail(item.t_e_t_1)">
                {{
                    item.t_e_t_1.currency_type == 1
                      ? currencyFormatEn(item.t_e_t_1.amount)
                      : currencyFormatKm(item.t_e_t_1.amount)
                }}
              </td> -->
            </tr>
          </template>
          <template slot="body.append">
            <tr>
              <td style="text-align:center " colspan="9" v-if="loader">
                <div class="lds-roller my-3">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-row 
        v-if="this.user.role_id == '61bf5eb0c73d7eea8634fbf4' || 
          this.user.role_id == '61d9a2a869e14b72d45f25d0'|| 
          this.user.role_id == '640d886e4f7dfc5b74997ee5'|| 
          this.user.role_id == '640d888d4f7dfc5b74997ee6'|| 
          this.user.role_id == '640d889e4f7dfc5b74997ee7'" 
        class="mt-5 mx-0 mb-3 justify-center">
          <span class="text-h6 mt-1">ការបង្ហាញឈ្មោះ </span>
           <v-btn
          :class="
            ch[0] && ch[0].is_show_username ? 'error mb-3 ml-3 ' : 'success mb-3 ml-3 '
          "
          @click="_updateIsShowUserName(ch[0], 11)"
          >{{
            ch[0] && ch[0].is_show_username
              ? "បានបិទ"
              : ch[0] && !ch[0].is_show_username
              ? "កំពុងបង្ហាញ"
              : "Loading...."
          }}</v-btn
        >
        </v-row>
          <hr class="w-100 mx-4">
         <v-row 
         v-if="this.user.role_id == '61bf5eb0c73d7eea8634fbf4' || 
          this.user.role_id == '61d9a2a869e14b72d45f25d0'|| 
          this.user.role_id == '640d886e4f7dfc5b74997ee5'|| 
          this.user.role_id == '640d888d4f7dfc5b74997ee6'|| 
          this.user.role_id == '640d889e4f7dfc5b74997ee7'"
         class="mt-5 justify-center">
          <span class="text-h6 mr-3 mt-1">កំណត់ Stream Url </span>
           <v-btn
          class="
           warning mb-3 ml-3'
          "
          @click="_showDialogUpdateStreamUrl(ch[0], 11)"
          ><v-icon>mdi-pencil</v-icon></v-btn
        >
        </v-row>
      </v-col>
    </v-row>
    <!--    Detail Each Match -->
    <v-dialog :value="dialog" max-width="1200" persistent transition="dialog-bottom-transition" style="z-index: 9999 !important">
      <v-card>
        <v-toolbar>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row class="pl-5">
            <v-col cols="12" sm="12" md="12" class="text-left mt-5">
              <v-toolbar-title>Detail Player Statement
                <span style="margin-left:20px;"></span> ( Channel Type:
                <span class ="detail_ch_number" >{{
                    dataRequest.channelType == 1 ? "1" : dataRequest.channelType == 2 ? "2" : dataRequest.channelType
                }}
                </span>)
              </v-toolbar-title>
            </v-col>

            <!-- <v-col cols="8" sm="8" md="8" class="text-right mb-4">
              <v-text-field v-model="v_search" append-icon="mdi-magnify" label="Search" single-line hide-details
                clearable class="mr-5" />
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="2" sm="2" md="2" class="text-right mb-4">
              <v-btn class="white--text btn-custom mr-4 mt-3" color="green darken-1" depressed @click="searchq" :disabled="getLoading">
              
                SEARCH {{loadingGG}}
              </v-btn>
            </v-col>
            <v-col cols="2" sm="2" md="2" class="text-right mb-4">
              <v-btn class="white--text btn-custom mr-4 mt-3" color="green darken-1" depressed @click="searchq" :disabled="loadingGG">
              
                Refresh
              </v-btn>
            </v-col> -->
          </v-row>
          <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <i class="fas fa-search"></i>
                </div>
              </div>
              <input v-model="v_search" type="text" class="form-control mr-1" />
              <button
                class="btn btn-primary"
                color="green darken-1"
                depressed
                @click="searchq"
              >
                SEARCH
              </button>
            </div>
            <br>
          <v-data-table style="font-family: 'khmer mef1'" class="elevation-1" item-key="date" :headers="headers"
            :footer-props="footerProps" :items="detailData ? detailData : []" :server-items-length="
              detailDataCount ? parseInt(detailDataCount.count) : null
            " :sort-desc.sync="dataRequest.descendingFormat" :sort-by.sync="dataRequest.sortByFormat"
            :page.sync="dataRequest.page" :items-per-page.sync="dataRequest.rowsPerPage" @update:page="updatePage"
            @update:items-per-page="updatePage" @update:sort-desc="updatePage" :loading="loadingGG"
            mobile-breakpoint="0">
            <template v-slot:[`item`]="{ item }">
              <tr>
                <td>
                  {{
                      detailData
                        ? detailData.indexOf(item) +
                        (dataRequest.page - 1) * dataRequest.rowsPerPage +
                        1
                        : "loading ..."
                  }}
                </td>
                <td>{{ item.app_id }}</td>
                <td>{{ item.public_ip }}</td>
                <td>{{ item.date }}</td>
                <td>{{ item.user_name }}</td>
                <td>{{ item.fight_no }}</td>
                <td>
                  {{ item.bet_name }}
                </td>
                <td class="amount-bet-color">
                  {{
                      dataRequest && dataRequest.currencyType == 2
                        ? currencyFormatKm(item.amount)
                        : dataRequest.currencyType &&
                          dataRequest.currencyType != 2
                          ? currencyFormatEn(item.amount)
                          : "Loading ..."
                  }}
                </td>
                <td class="font-weight-bold">
                  {{ currencyFormatEn(item.payout) }}
                </td>
              </tr>
            </template>
            <template slot="body.append">
              <td :colspan="6" class="text-xs-center pl-4 py-2 ">
                {{ $t("title.total") }}
              </td>
              <td class="text-xs-center total-style pl-4  py-2">
                {{
                    dataRequest &&
                      dataRequest.currencyType == 2 &&
                      detailDataCount
                      ? currencyFormatKm(detailDataCount.amount)
                      : dataRequest.currencyType &&
                        dataRequest.currencyType != 2 &&
                        detailDataCount
                        ? currencyFormatEn(detailDataCount.amount)
                        : "Loading ..."
                }}
              </td>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmDialog" persistent max-width="500px">
      <v-card>
        <v-card-title class="text-h5 py-5">
          {{
            targetChannel && targetChannel.enable
              ? "បិទដំណើរការ Channel "
              : "បើកដំណើរការ Channel "
          }}
        </v-card-title>
        <v-card-actions>
          <v-btn
            color="blue-grey"
            class="ma-2 white--text"
            @click="_closeDialog"
            >Cancel</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            class="ma-2 white--text"
            :color="targetChannel && targetChannel.enable ? 'error' : 'success'"
            @click="_takeAction"
            >{{
              targetChannel && targetChannel.enable
                ? "Inactive Channel"
                : "Active Channel"
            }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmDialogIsShowUserName" persistent max-width="500px">
      <v-card>
        <v-card-title class="text-h5 py-5">
          {{
            selectedChannel && selectedChannel.is_show_username
              ? "បិទការបង្ហាញ ឈ្មោះ"
              : "បើកការបង្ហាញ ឈ្មោះ"
          }}
        </v-card-title>
        <v-card-actions>
          <v-btn
            color="blue-grey"
            class="ma-2 white--text"
            @click="_closeDialogIsShowUserName"
            >Cancel</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            class="ma-2 white--text"
            :color="selectedChannel && selectedChannel.is_show_username ? 'success' : 'error'"
            @click="_takeActionIsShowUserName"
            >{{
              selectedChannel && selectedChannel.is_show_username
                ? "បើកការបង្ហាញ ឈ្មោះ"
                : "បិទការបង្ហាញ ឈ្មោះ"
            }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <DialogUpdateStream
      :dialogUpdateStream="dialogUpdateStream"
      :selectedUpdateChanel="selectedUpdateChanel"
      :loading="getLoading"
      v-on:submit="_handleSubmit"
      v-on:closeDialogUpdateStream="_closeDialogUpdateStream"
    />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import moment from "moment";

export default {
  components: {
      DialogUpdateStream: () => import("./_components/DialogUpdateStream.vue"),
  },

  data: () => ({
    v_search: null,
    getLoading: false,
    headers: [
      {
        text: "ល.រ",
        align: "start",
        value: "no",
      },
      { text: "App Name", value: "app_id" },
      { text: "Public IP", value: "public_ip" },
      { text: "កាលបរិច្ឆេទ", value: "date" },
      { text: "ឈ្មោះ", value: "user_name" },
      { text: "ប្រកួតលេខ", value: "fight_no" },
      { text: "ប្រភេទចាក់", value: "co_type_of_betting.name" },
      { text: "ប្រាក់ចាក់", value: "amount" },
      { text: "Payout", value: "payout" },
    ],
    headers1: [
      {
        text: "Meron",
        value: "Meron_Ch1",
        format: (v) => v && Number(v).toFixed(2),
        align: "left",
      },
      {
        text: "Wala",
        value: "Wala_Ch1",
        format: (v) => v && Number(v).toFixed(2),
        align: "left",
      },
    ],
    headers2: [
      {
        text: "Meron",
        value: "Meron_Ch2",
        format: (v) => v && Number(v).toFixed(2),
        align: "left",
      },
      {
        text: "Wala",
        value: "Wala_Ch2",
        format: (v) => v && Number(v).toFixed(2),
        align: "left",
      },
    ],
    headers3: [
      {
        text: "Meron",
        value: "Meron_Ch3",
        format: (v) => v && Number(v).toFixed(2),
        align: "left",
      },
      {
        text: "Wala",
        value: "Wala_Ch3",
        format: (v) => v && Number(v).toFixed(2),
        align: "left",
      },
    ],
    headers4: [
      {
        text: "Meron",
        value: "Meron_Ch4",
      },
      {
        text: "Wala",
        value: "Wala_Ch4",
      },
    ],
    StatementObj: [
      {
        currency: "$",
        rat_1: {
          amount: 0,
          betting_type: "1-rat",
          currency_type: 1,
          channel_type:11,
        },
        ox_1: {
          amount: 0,
          betting_type: "2-ox",
          currency_type: 1,
          channel_type:11,
        },
        tiger_1: {
          amount: 0,
          betting_type: "3-tiger",
          currency_type: 1,
          channel_type:11,
        },
        rabbit_1: {
          amount: 0,
          betting_type: "4-rabbit",
          currency_type: 1,
          channel_type:11,
        },
        dragon_1: {
          amount: 0,
          betting_type: "5-dragon",
          currency_type: 1,
          channel_type:11,
        },
        snake_1: {
          amount: 0,
          betting_type: "6-snake",
          currency_type: 1,
          channel_type:11,
        },
        horse_1: {
          amount: 0,
          betting_type: "7-horse",
          currency_type: 1,
          channel_type:11,
        },
        goat_1: {
          amount: 0,
          betting_type: "8-goat",
          currency_type: 1,
          channel_type:11,
        },
        monkey_1: {
          amount: 0,
          betting_type: "9-monkey",
          currency_type: 1,
          channel_type:11,
        },
        rooster_1: {
          amount: 0,
          betting_type: "10-rooster",
          currency_type: 1,
          channel_type:11,
        },
        dog_1: {
          amount: 0,
          betting_type: "11-dog",
          currency_type: 1,
          channel_type:11,
        },
        pig_1: {
          amount: 0,
          betting_type: "12-pig",
          currency_type: 1,
          channel_type:11,
        },
        thin_1: {
          amount: 0,
          betting_type: "thin",
          currency_type: 1,
          channel_type:11,
        },
        thick_1: {
          amount: 0,
          betting_type: "thick",
          currency_type: 1,
          channel_type:11,
        },
        red_1: {
          amount: 0,
          betting_type: "red",
          currency_type: 1,
          channel_type:11,
        },
        blue_1: {
          amount: 0,
          betting_type: "blue",
          currency_type: 1,
          channel_type:11,
        },
        // even_1: 0,
        // odd_1: 0,
        // o_t_t_1: 0,
        // f_f_s_1: 0,
        // s_e_n_1: 0,
        // t_e_t_1: 0,
      },
      {
        currency: "៛",
        rat_1: {
          amount: 0,
          betting_type: "1-rat",
          currency_type: 2,
          channel_type:11,
        },
        ox_1: {
          amount: 0,
          betting_type: "2-ox",
          currency_type: 2,
          channel_type:11,
        },
        tiger_1: {
          amount: 0,
          betting_type: "3-tiger",
          currency_type: 2,
          channel_type:11,
        },
        rabbit_1: {
          amount: 0,
          betting_type: "4-rabbit",
          currency_type: 2,
          channel_type:11,
        },
        dragon_1: {
          amount: 0,
          betting_type: "5-dragon",
          currency_type: 2,
          channel_type:11,
        },
        snake_1: {
          amount: 0,
          betting_type: "6-snake",
          currency_type: 2,
          channel_type:11,
        },
        horse_1: {
          amount: 0,
          betting_type: "7-horse",
          currency_type: 2,
          channel_type:11,
        },
        goat_1: {
          amount: 0,
          betting_type: "8-goat",
          currency_type: 2,
          channel_type:11,
        },
        monkey_1: {
          amount: 0,
          betting_type: "9-monkey",
          currency_type: 2,
          channel_type:11,
        },
        rooster_1: {
          amount: 0,
          betting_type: "10-rooster",
          currency_type: 2,
          channel_type:11,
        },
        dog_1: {
          amount: 0,
          betting_type: "11-dog",
          currency_type: 2,
          channel_type:11,
        },
        pig_1: {
          amount: 0,
          betting_type: "12-pig",
          currency_type: 2,
          channel_type:11,
        },
        thin_1: {
          amount: 0,
          betting_type: "thin",
          currency_type: 2,
          channel_type:11,
        },
        thick_1: {
          amount: 0,
          betting_type: "thick",
          currency_type: 2,
          channel_type:11,
        },
        red_1: {
          amount: 0,
          betting_type: "red",
          currency_type: 2,
          channel_type:11,
        },
        blue_1: {
          amount: 0,
          betting_type: "blue",
          currency_type: 2,
          channel_type:11,
        },
        // even_1: 0,
        // odd_1: 0,
        // o_t_t_1: 0,
        // f_f_s_1: 0,
        // s_e_n_1: 0,
        // t_e_t_1: 0,
      },
    ],
    footerProps: {
      "items-per-page-options": [10, 20, 50, 100, 200],
    },
    dataRequest: {
      descendingFormat: [true],
      sortByFormat: ["date"],
      descending: false,
      sortBy: null,
      page: 1,
      rowsPerPage: 10,
      fields: [
        "app_id",
        "public_ip",
        "bet_name",
        "user_name",
        "date",
        "fight_no",
        "amount",
      ],
      search: "",
      fightNo: null,
      level: 1,
    },
    data: {
      descendingFormat: [true],
      sortByFormat: ["_id"],
      descending: true,
      sortBy: null,
      page: 1,
      rowsPerPage: 10,
      fields: [
        "app_id",
        "public_ip",
        "bet_name",
        "user_name",
        "date",
        "fight_no",
        "amount",
      ],
      search: "",
      startDate: new Date().toISOString().substr(0, 10),
      endDate: new Date().toISOString().substr(0, 10),
    },
    dialog: false,
    loading: false,
    loader: false,
    detailData: [],
    detailDataCount: [],
    ch: [],
    confirmDialog: false,
    targetChannel: [],
    selectedChannel:[],
    selectedUpdateChanel:[],
    dialogUpdateStream:false,
    confirmDialogIsShowUserName: false,
    channel_type: null,
    loadingGG: false,
  }),
  watch: {
    statementC: function (newV) {
      if (newV) this.getFirstData();
    },
    StatementObj: function () { },
    ch: function () { },
    userp:function() {}
  },
  computed: {
    ...mapGetters("$_modules", { user: "getUser" }),
    ...mapGetters("$_yukiReportOutStandingCm", {
      statementC: "getCStatement",
      channels: "getChannel",
    }),
  },
  async created() {
     await  this.fetchUser();
     await this.loadChannel();
     await this.getData();
  },
  methods: {
    processTableHeaders(headers) {
      const nested = !!headers.some((h) => h.children);

      if (nested) {
        let children = [];

        const h = headers.map((h) => {
          if (h.children && h.children.length > 0) {
            children.push(...h.children);

            return {
              rowspan: 1,
              colspan: h.children.length,
              text: h.text,
              align: h.align,
            };
          }
          return {
            rowspan: 2,
            colspan: 1,
            text: h.text,
            align: h.align,
          };
        });

        return {
          children: children,
          parents: h,
        };
      }
      return {
        parents: headers,
      };
    },
    moment,
    currencyFormatEn(num) {
      num = parseFloat(num);
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    currencyFormatKm(num) {
      num = parseFloat(num);
      return num.toLocaleString("en");
    },
    _goToDetail(selectedData) {
      this.detailData = [];
      this.detailDataCount = [];
      let bt;
      if (
        selectedData.betting_type == "1-rat" ||
        selectedData.betting_type == "Rat"
      )
        bt = "Rat";
      if (
        selectedData.betting_type == "2-ox" ||
        selectedData.betting_type == "Ox"
      )
        bt = "Ox";
      if (
        selectedData.betting_type == "3-tiger" ||
        selectedData.betting_type == "Tiger"
      )
        bt = "Tiger";
      if (
        selectedData.betting_type == "4-rabbit" ||
        selectedData.betting_type == "Rabbit"
      )
        bt = "Rabbit";
      if (
        selectedData.betting_type == "5-dragon" ||
        selectedData.betting_type == "Dragon"
      )
        bt = "Dragon";
      if (
        selectedData.betting_type == "6-snake" ||
        selectedData.betting_type == "Snake"
      )
        bt = "Snake";
      if (
        selectedData.betting_type == "7-horse" ||
        selectedData.betting_type == "Horse"
      )
        bt = "Horse";
      if (
        selectedData.betting_type == "8-goat" ||
        selectedData.betting_type == "Goat"
      )
        bt = "Goat";
      if (
        selectedData.betting_type == "9-monkey" ||
        selectedData.betting_type == "Monkey"
      )
        bt = "Monkey";
      if (
        selectedData.betting_type == "10-rooster" ||
        selectedData.betting_type == "Rooster"
      )
        bt = "Rooster";
      if (
        selectedData.betting_type == "11-dog" ||
        selectedData.betting_type == "Dog"
      )
        bt = "Dog";
      if (
        selectedData.betting_type == "12-pig" ||
        selectedData.betting_type == "Pig"
      )
        bt = "Pig";
      if (
        selectedData.betting_type == "under" ||
        selectedData.betting_type == "Under"
      )
        bt = "under";
      if (
        selectedData.betting_type == "over" ||
        selectedData.betting_type == "Over"
      )
        bt = "over";
      if (
        selectedData.betting_type == "blue" ||
        selectedData.betting_type == "BLUE"
      )
        bt = "blue";
      if (
        selectedData.betting_type == "red" ||
        selectedData.betting_type == "RED"
      )
        bt = "red";
      // if (
      //   selectedData.betting_type == "even" ||
      //   selectedData.betting_type == "EVEN"
      // )
      //   bt = "even";
      // if (
      //   selectedData.betting_type == "odd" ||
      //   selectedData.betting_type == "ODD"
      // )
      //   bt = "odd";
      // if (
      //   selectedData.betting_type == "1-2-3"
      // )
      //   bt = "1-2-3";
      // if (
      //   selectedData.betting_type == "4-5-6"
      // )
      //   bt = "4-5-6";
      // if (
      //   selectedData.betting_type == "7-8-9"
      // )
      //   bt = "7-8-9";
      // if (
      //   selectedData.betting_type == "10-11-12"
      // )
      //   bt = "10-11-12";
        
      this.dataRequest.bettingType = bt;
      this.dataRequest.channelType = selectedData.channel_type;
      this.dataRequest.currencyType = selectedData.currency_type;
      this.dataRequest.fightNo = selectedData.fight_no;
      console.log("this.dataRequest",this.dataRequest);
      this.loadDetailData();
      this.dialog = true;
    },
    async loadDetailData() {
      this.loadingGG = true;
       this.dataRequest.parentId = this.user._id;
      this.dataRequest.sortByFormat.length < 1
        ? (this.dataRequest.sortBy = "_id")
        : (this.dataRequest.sortBy = this.dataRequest.sortByFormat[0]);
      this.dataRequest.descendingFormat.length > 0
        ? (this.dataRequest.descending = this.dataRequest.descendingFormat[0])
        : (this.dataRequest.descending = true);
      await this.$request
        .post({
          url: "outstanding/detailYuki",
          data: this.dataRequest,
        })
        .then((res) => {
          if (res.data.code) {
            this.detailData = res.data.data.object;
            this.detailDataCount = res.data.data.objectCount;
            this.loadingGG = false;
          } else {
            this.loadingGG = false;
          }
        });
    },
    async getData() {
      if(this.user){
        let data = {
           objCurrentChannel : this.ch,
           parentId : this.user._id
        }
         try {
        this.loader = true;
        await this.fetchListStatement(data);
        this.loader = false;
      } catch (error) {
        // console.log(error)
      }
      }
  
     
    },
    getFirstData() {
      if (this.statementC) {
        for (let i = 0; i < this.statementC.object.length; i++) {
          if (this.statementC.object[i]._id.channel_type == 11) {
            if (this.statementC.object[i]._id.currency_type == 1) {
              if (this.statementC.object[i]._id.betting_type == "Rat")
                this.StatementObj[0].rat_1 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
              if (this.statementC.object[i]._id.betting_type == "Ox")
                this.StatementObj[0].ox_1 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
              if (this.statementC.object[i]._id.betting_type == "Tiger")
                this.StatementObj[0].tiger_1 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
                if (this.statementC.object[i]._id.betting_type == "Rabbit")
                this.StatementObj[0].rabbit_1 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
                if (this.statementC.object[i]._id.betting_type == "Dragon")
                this.StatementObj[0].dragon_1 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
                if (this.statementC.object[i]._id.betting_type == "Snake")
                this.StatementObj[0].snake_1 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
                if (this.statementC.object[i]._id.betting_type == "Horse")
                this.StatementObj[0].horse_1 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
                if (this.statementC.object[i]._id.betting_type == "Goat")
                this.StatementObj[0].goat_1 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
                if (this.statementC.object[i]._id.betting_type == "Monkey")
                this.StatementObj[0].monkey_1 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
                if (this.statementC.object[i]._id.betting_type == "Rooster")
                this.StatementObj[0].rooster_1 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
                if (this.statementC.object[i]._id.betting_type == "Dog")
                this.StatementObj[0].dog_1 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
                if (this.statementC.object[i]._id.betting_type == "Pig")
                this.StatementObj[0].pig_1 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
                if (this.statementC.object[i]._id.betting_type == "under")
                this.StatementObj[0].thin_1 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
                if (this.statementC.object[i]._id.betting_type == "over")
                this.StatementObj[0].thick_1 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
                if (this.statementC.object[i]._id.betting_type == "blue")
                this.StatementObj[0].blue_1 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
                if (this.statementC.object[i]._id.betting_type == "red")
                this.StatementObj[0].red_1 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
                // if (this.statementC.object[i]._id.betting_type == "even")
                // this.StatementObj[0].even_1 = {
                //   amount: this.statementC.object[i].amount,
                //   betting_type: this.statementC.object[i]._id.betting_type,
                //   currency_type: this.statementC.object[i]._id.currency_type,
                //   channel_type: this.statementC.object[i]._id.channel_type,
                //   fight_no: this.statementC.object[i]._id.fight_no,
                // };
                // if (this.statementC.object[i]._id.betting_type == "odd")
                // this.StatementObj[0].odd_1 = {
                //   amount: this.statementC.object[i].amount,
                //   betting_type: this.statementC.object[i]._id.betting_type,
                //   currency_type: this.statementC.object[i]._id.currency_type,
                //   channel_type: this.statementC.object[i]._id.channel_type,
                //   fight_no: this.statementC.object[i]._id.fight_no,
                // };
                // if (this.statementC.object[i]._id.betting_type == "1-2-3")
                // this.StatementObj[0].o_t_t_1 = {
                //   amount: this.statementC.object[i].amount,
                //   betting_type: this.statementC.object[i]._id.betting_type,
                //   currency_type: this.statementC.object[i]._id.currency_type,
                //   channel_type: this.statementC.object[i]._id.channel_type,
                //   fight_no: this.statementC.object[i]._id.fight_no,
                // };
                // if (this.statementC.object[i]._id.betting_type == "4-5-6")
                // this.StatementObj[0].f_f_s_1 = {
                //   amount: this.statementC.object[i].amount,
                //   betting_type: this.statementC.object[i]._id.betting_type,
                //   currency_type: this.statementC.object[i]._id.currency_type,
                //   channel_type: this.statementC.object[i]._id.channel_type,
                //   fight_no: this.statementC.object[i]._id.fight_no,
                // };
                // if (this.statementC.object[i]._id.betting_type == "7-8-9")
                // this.StatementObj[0].s_e_n_1 = {
                //   amount: this.statementC.object[i].amount,
                //   betting_type: this.statementC.object[i]._id.betting_type,
                //   currency_type: this.statementC.object[i]._id.currency_type,
                //   channel_type: this.statementC.object[i]._id.channel_type,
                //   fight_no: this.statementC.object[i]._id.fight_no,
                // };
                // if (this.statementC.object[i]._id.betting_type == "10-11-12")
                // this.StatementObj[0].t_e_t_1 = {
                //   amount: this.statementC.object[i].amount,
                //   betting_type: this.statementC.object[i]._id.betting_type,
                //   currency_type: this.statementC.object[i]._id.currency_type,
                //   channel_type: this.statementC.object[i]._id.channel_type,
                //   fight_no: this.statementC.object[i]._id.fight_no,
                // };
            }
            if (this.statementC.object[i]._id.currency_type == 2) {
              if (this.statementC.object[i]._id.betting_type == "Rat")
                this.StatementObj[1].rat_1 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
              if (this.statementC.object[i]._id.betting_type == "Ox")
                this.StatementObj[1].ox_1 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
              if (this.statementC.object[i]._id.betting_type == "Tiger")
                this.StatementObj[1].tiger_1 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
                if (this.statementC.object[i]._id.betting_type == "Rabbit")
                this.StatementObj[1].rabbit_1 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
                if (this.statementC.object[i]._id.betting_type == "Dragon")
                this.StatementObj[1].dragon_1 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
                if (this.statementC.object[i]._id.betting_type == "Snake")
                this.StatementObj[1].snake_1 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
                if (this.statementC.object[i]._id.betting_type == "Horse")
                this.StatementObj[1].horse_1 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
                if (this.statementC.object[i]._id.betting_type == "Goat")
                this.StatementObj[1].goat_1 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
                if (this.statementC.object[i]._id.betting_type == "Monkey")
                this.StatementObj[1].monkey_1 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
                if (this.statementC.object[i]._id.betting_type == "Rooster")
                this.StatementObj[1].rooster_1 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
                if (this.statementC.object[i]._id.betting_type == "Dog")
                this.StatementObj[1].dog_1 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
                if (this.statementC.object[i]._id.betting_type == "Pig")
                this.StatementObj[1].pig_1 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
                if (this.statementC.object[i]._id.betting_type == "under")
                this.StatementObj[1].thin_1 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
                if (this.statementC.object[i]._id.betting_type == "over")
                this.StatementObj[1].thick_1 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
                if (this.statementC.object[i]._id.betting_type == "blue")
                this.StatementObj[1].blue_1 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
                if (this.statementC.object[i]._id.betting_type == "red")
                this.StatementObj[1].red_1 = {
                  amount: this.statementC.object[i].amount,
                  betting_type: this.statementC.object[i]._id.betting_type,
                  currency_type: this.statementC.object[i]._id.currency_type,
                  channel_type: this.statementC.object[i]._id.channel_type,
                  fight_no: this.statementC.object[i]._id.fight_no,
                };
                // if (this.statementC.object[i]._id.betting_type == "even")
                // this.StatementObj[1].even_1 = {
                //   amount: this.statementC.object[i].amount,
                //   betting_type: this.statementC.object[i]._id.betting_type,
                //   currency_type: this.statementC.object[i]._id.currency_type,
                //   channel_type: this.statementC.object[i]._id.channel_type,
                //   fight_no: this.statementC.object[i]._id.fight_no,
                // };
                // if (this.statementC.object[i]._id.betting_type == "odd")
                // this.StatementObj[1].odd_1 = {
                //   amount: this.statementC.object[i].amount,
                //   betting_type: this.statementC.object[i]._id.betting_type,
                //   currency_type: this.statementC.object[i]._id.currency_type,
                //   channel_type: this.statementC.object[i]._id.channel_type,
                //   fight_no: this.statementC.object[i]._id.fight_no,
                // };
                // if (this.statementC.object[i]._id.betting_type == "1-2-3")
                // this.StatementObj[1].o_t_t_1 = {
                //   amount: this.statementC.object[i].amount,
                //   betting_type: this.statementC.object[i]._id.betting_type,
                //   currency_type: this.statementC.object[i]._id.currency_type,
                //   channel_type: this.statementC.object[i]._id.channel_type,
                //   fight_no: this.statementC.object[i]._id.fight_no,
                // };
                // if (this.statementC.object[i]._id.betting_type == "4-5-6")
                // this.StatementObj[1].f_f_s_1 = {
                //   amount: this.statementC.object[i].amount,
                //   betting_type: this.statementC.object[i]._id.betting_type,
                //   currency_type: this.statementC.object[i]._id.currency_type,
                //   channel_type: this.statementC.object[i]._id.channel_type,
                //   fight_no: this.statementC.object[i]._id.fight_no,
                // };
                // if (this.statementC.object[i]._id.betting_type == "7-8-9")
                // this.StatementObj[1].s_e_n_1 = {
                //   amount: this.statementC.object[i].amount,
                //   betting_type: this.statementC.object[i]._id.betting_type,
                //   currency_type: this.statementC.object[i]._id.currency_type,
                //   channel_type: this.statementC.object[i]._id.channel_type,
                //   fight_no: this.statementC.object[i]._id.fight_no,
                // };
                // if (this.statementC.object[i]._id.betting_type == "10-11-12")
                // this.StatementObj[1].t_e_t_1 = {
                //   amount: this.statementC.object[i].amount,
                //   betting_type: this.statementC.object[i]._id.betting_type,
                //   currency_type: this.statementC.object[i]._id.currency_type,
                //   channel_type: this.statementC.object[i]._id.channel_type,
                //   fight_no: this.statementC.object[i]._id.fight_no,
                // };
            }
          }
        }
      }
    },
    async loadChannel() {
      this.getLoading = true;
      await this.$request
        .get({
          url: "yuki/getCurrentChannel",
          data: this.dataRequest,
        })
        .then((res) => {
          if (res.data.code) {
            this.ch = res.data.data.objCurrentChannel;
            this.getLoading = false;
          } else {
            this.getLoading = false;
          }
        });
    },
    searchq() {
      this.loadingGG = true;
      this.dataRequest.search = this.v_search;
      this.updatePage();
    },
    updatePage() {
      this.loadingGG = true;
      this.loadDetailData();
      this.loadingGG = false;
    },
    searchData(){
       this.getData()
    },
    _updateChanel(ch, channelType) {
      this.targetChannel = ch;
      this.channel_type = channelType;
      this.confirmDialog = true;
    },
    _closeDialog() {
      this.targetChannel = [];
      this.channel_type = null;
      this.confirmDialog = false;
    },
    async _takeAction() {
      let data = {
        enable: !this.targetChannel.enable,
        channel_type: this.channel_type,
      };
      // console.log("_takeAction", this.targetChannel, this.channel_type)
      await this.$request
        .post({
          url: "yuki/updateEnableStatusChannel",
          data: data,
        })
        .then((res) => {
          if (res.data.code) {
            this.loadChannel();
            this.getLoading = false;
          } else {
            // console.log(res.data)
            this.getLoading = false;
          }
        });
      // console.log("_takeAction", this.targetChannel, this.channel_type)
      this.targetChannel = [];
      this.channel_type = null;
      this.confirmDialog = false;
    },
    _updateIsShowUserName(ch, channelType) {
      this.selectedChannel = ch;
      this.channel_type = channelType;
      this.confirmDialogIsShowUserName = true;
    },
    _closeDialogIsShowUserName() {
      this.selectedChannel = [];
      this.channel_type = null;
      this.confirmDialogIsShowUserName = false;
    },
    async _takeActionIsShowUserName() {
      let data = {
        is_show_username: !this.selectedChannel.is_show_username,
        channel_type: this.channel_type,
      };
      await this.$request
        .post({
          url: "yuki/updateIsShowUsernameChannel",
          data: data,
        })
        .then((res) => {
          if (res.data.code) {
            this.loadChannel();
            this.getLoading = false;
          } else {
            // console.log(res.data)
            this.getLoading = false;
          }
        });
      // console.log("_takeAction", this.targetChannel, this.channel_type)
      this.selectedChannel = [];
      this.channel_type = null;
      this.confirmDialogIsShowUserName = false;
    },
    _showDialogUpdateStreamUrl(selectedItem, channelType){
      this.selectedUpdateChanel = selectedItem;
      this.selectedUpdateChanel.channel_type = channelType;
      this.dialogUpdateStream = true
    },
    _closeDialogUpdateStream(){
      this.selectedUpdateChanel = [];
      this.dialogUpdateStream = false;
    },
    async _handleSubmit(){
      let data = {
        live_urls : this.selectedUpdateChanel.live_urls,
        channel_type : this.selectedUpdateChanel.channel_type,
        is_maintain : this.selectedUpdateChanel.is_maintain,
        is_stop_video : this.selectedUpdateChanel.is_stop_video
      }
      await this.updateChannelUrl({ vm: this, data:data});
      this._closeDialogUpdateStream();
    },

    ...mapMutations("$_yukiReportOutStandingCm", ["LIST_STATEMENT"]),
    ...mapActions("$_yukiReportOutStandingCm", [
      "fetchListStatement",
      "fetchGetCurrentChannel",
      "updateChannelUrl",
      "updateListStatement"
    ]),
     ...mapActions("$_modules", [
      "fetchUser",
    ]),
    ...mapMutations("$_modules", ["UPDATE_USER"]),
  },
};
</script>

<style lang="scss" scoped>

.theme--light.v-data-table {
    background-color: #fff;
    color: rgba(0,0,0,.87);
    padding-top: 15px;
}
.table-style {
  font-family: "khmer mef1";
  border: 1px solid #9f9393;
}
.fino {
  color: #6699cc;
  font-size: 24px;
  font-weight: bold;
  margin-left: 10px;
}

.cm-title {
  color: black;
  font-weight: 900;
  font-size: 32px;
  position: absolute;
  top: 105px;
  left: 50%;
  margin-left: -90px;
}

.row-pointer:hover {
  cursor: pointer;
  background-color: honeydew;
}

.users {
  width: 50%;
  float: left;

  h3 {
    font-size: 17px;
  }
}

table {
  font-family: "khmer mef1" !important;
}

.amount-bet-color {
  color: goldenrod !important;
}

.win-color {
  color: rgb(57, 175, 214);
  font-weight: bold;
}

.lose-color {
  color: #ba2d4a;
  font-weight: bold;
}

.tab-filter {
  display: flex;
  flex-direction: row;
}

.row {
  display: flex;
  flex-direction: row;
}

.between {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.w-full {
  width: 100%;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #cef;
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.tie-color {
  color: green;
}
.channel_number_style{
  font-size:1.8rem; 
  color:white; 
  font-weight:bold; 
  padding: 5px 14px;
  margin-left:5px;
  background-color: #6699cc;
  border-radius: 100px;
}
.detail_ch_number{
  margin-left:10px; color:#6699cc; font-size:24px; font-weight:bold 
}
</style>
