/* eslint-disable no-unused-vars */
import Request from '@/services/axios'
import Vue from 'vue';

let request = new Request;

const fetchCurrentChannel = async({ commit }, data) => {
    const response = await request.get({
        url: 'abong/getCurrentChannelAndResult',
        data
    });
    if (response.data.code) {
        commit('UPDATE_CHANNEL', response.data.data.objSnChannel);
        commit('SUMMARY_RESULT', response.data.data.objSummarySnResult);
        commit('RESULT_TABLE', response.data.data.objSnResult);
    }
};
const resetResult = async ({ commit }, { vm }) => {
    const response = await request.get({
        url: 'abong/resetResult'
    });
    if (response.data.code) {
        commit('UPDATE_CHANNEL', response.data.data.objSnChannel);
        commit('SUMMARY_RESULT', {
        one: 0,
        two: 0,
        three: 0,
        four: 0,
        five: 0,
        six: 0
    },);
        commit('RESULT_TABLE', {});
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }
};

const createResult = async({ commit }, { vm, data }) => {
    commit('LOADINGRESULT', true);
    const response = await request.post({
        url: 'abong/createResultAndNewChannel',
        data
    });
    if (response.data.code) {
        commit('UPDATE_CHANNEL', response.data.data.objSnChannel);
        commit('SUMMARY_RESULT', response.data.data.objSummarySnResult);
        commit('RESULT_TABLE', response.data.data.objSnResult);
        commit('LOADINGRESULT', false);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        commit('LOADINGRESULT', false);
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }
};
const scanResult = async({ commit }, { vm, data }) => {
    commit('LOADINGRESULT', true);
    const response = await request.post({
        url: 'abong/scanWinLose',
        data
    });
    if (response.data.code) {
        commit('UPDATE_CHANNEL', response.data.data.objSnChannel);
        commit('SUMMARY_RESULT', response.data.data.objSummarySnResult);
        commit('RESULT_TABLE', response.data.data.objSnResult);
        commit('LOADINGRESULT', false);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        commit('LOADINGRESULT', false);
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }
};

const updateChanelIsReady = async ({ commit }, { vm, data }) => {
    const response = await request.get({
        url: 'abong/isReady/' + data
    });
    if (response.data.code) {
        commit('UPDATE_CHANNEL', response.data.data.objSnChannel);
        commit('LOADINGRESULT', false);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }
};

const fetchBtnResult = async({ commit }, data) => {
    const response = await request.get({
        url: 'abong/getSnNumber',
        data
    });
    if (response.data.code) {
        commit('BTN_RESULT', response.data.data.objSnNumber);
    }
};
const updateChannel = async({ commit }, { vm, data }) => {
    commit('LOADING', true);
    const response = await request.put({
        url: 'abong',
        data
    });
    if (response.data) {
        commit('UPDATE_CHANNEL', response.data.data.objSnChannel);
        commit('LOADING', false);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }
};
const _popUpDialogResult =  ({commit}, payload) => {
    commit('POPUP_DIALOG_RESULT', payload);
};
const _popUpDialogDealer =  ({commit}, payload) => {
    commit('POPUP_DIALOG_SCAN_DEALER', payload);
};

const _fetchGetDealerInfo = async ({ commit },  { vm, data }) => {
    const response = await request.post({
        url: 'dealer/scanBarcode',
        data
    });
    if (response.data.code) {
        Vue.$cookies.set("dealerId", data , 0 );
        commit('UPDATE_DEALER_INFO', response.data.data);
        commit('POPUP_DIALOG_SCAN_DEALER', false);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    }
    else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }
};
const submitCardCode = async ({ commit },  { vm, data }) => {
    commit("ALLOW_SCAN", true);
    let response = await request.post({
        url: 'abong/scanCard',
        data
    });
    if (response.data.code) {
        if(response.data.data.objSnChannel) {
            commit('RESULT_EVENT', false);
            commit('UPDATE_CHANNEL', response.data.data.objSnChannel);
            vm.$toastr.s(`${response.data.message.descriptions}`);
        }
        const res = await request.get({
            url: "abong/getScanCard",
            data,
          });
          if (res.data.code) {
            commit("CURRENT_CARD", res.data.data);
          }
    }else{
        vm.$toastr.e(`${response.data.message.descriptions}`);
    }
    commit("ALLOW_SCAN", false);
};
const fetchCurrentCard = async ({ commit }, data) => {
    const response = await request.get({
        url: 'abong/getScanCard',
        data
    });
    if (response.data.code) {
        commit('CURRENT_CARD', response.data.data);
    }
};
const UpdateClearResult = async ({ commit }, { vm, data }) => {
    // commit('LOADINGRESULT', true);
    const response = await request.post({
        url: 'abong/clearCard',
        data
    });
    if (response.data.code) {
        // commit('UPDATE_CHANNEL', response.data.data.objCoChannel);
        commit('SUMMARY_RESULT', response.data.data.objSummarySnResult);
        commit('RESULT_TABLE', response.data.data.objSnResult);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }
};


export default {
    fetchCurrentChannel,
    fetchBtnResult,
    createResult,
    scanResult,
    updateChannel,
    updateChanelIsReady,
    resetResult,
    _popUpDialogResult,
    _fetchGetDealerInfo,
    _popUpDialogDealer,
    submitCardCode,
    fetchCurrentCard,
    UpdateClearResult
    
};