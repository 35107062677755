/* eslint-disable no-unused-vars */
import Request from '@/services/axios'
import Vue from 'vue';

let request = new Request;

const fetchCurrentChannel = async ({ commit }, data) => {
    const response = await request.get({
        url: 'dragon/getCurrentChannelAndResult',
        data
    });
    if (response.data.code) {
        commit('UPDATE_KDPAYOUT', response.data.data.objKdPayout);
        commit('UPDATE_CHANNEL', response.data.data.objCoChannel);
        commit('SUMMARY_RESULT', response.data.data.objSummaryCoResult);
        commit('RESULT_TABLE', response.data.data.objCoResult);
        commit('RESULT_EVENT', false);
    }
};

const fetchCurrentCard = async ({ commit }, data) => {
    const response = await request.get({
        url: 'dragon/getScanCard',
        data
    });
    if (response.data.code) {
        commit('CURRENT_CARD', response.data.data);
    }
};
const fetchCurrentTotal = async ({ commit }, data) => {
    const response = await request.get({
      url: "outstandingByChannel/9",
      data,
    });
    console.log("response.data.data",response.data.data);
    if (response.data.code) {
      commit("CURRENT_TOTAL", response.data.data);
    }
  };
const createResult = async ({ commit }, { vm, data }) => {
    commit('LOADINGRESULT', true);
    const response = await request.post({
        url: 'dragon/createResultAndNewChannel',
        data
    });
    if (response.data.code) {
        commit('UPDATE_CHANNEL', response.data.data.objCoChannel);
        commit('SUMMARY_RESULT', response.data.data.objSummaryCoResult);
        commit('RESULT_TABLE', response.data.data.objCoResult);
        commit('LOADINGRESULT', false);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }

};

const UpdateChanelStatus = async ({ commit }, { vm, data }) => {
    // commit('LOADINGRESULT', true);
    const response = await request.put({
        url: 'dragon/updateStatusChannel',
        data
    });
    if (response.data.code) {
        commit('UPDATE_CHANNEL', response.data.data.objCoChannel);
        if(response.data.data.objSummaryCoResult && response.data.data.objCoResult){
            commit('SUMMARY_RESULT', response.data.data.objSummaryCoResult);
            commit('RESULT_TABLE', response.data.data.objCoResult);
        }
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }
};

const UpdateClearCard = async ({ commit }, { vm, data }) => {
    // commit('LOADINGRESULT', true);
    const response = await request.post({
        url: 'dragon/clearCard',
        data
    });
    if (response.data.code) {
        // commit('UPDATE_CHANNEL', response.data.data.objCoChannel);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }
};

const fetchBtnResult = async ({ commit }, data) => {
    const response = await request.get({
        url: 'dragon/getCoTypeOfBetting',
        data
    });
    if (response.data.code) {
        commit('BTN_RESULT', response.data.data.objCoTypeOfBetting);
    }
};
const updateChannel = async ({ commit }, { vm, data }) => {
    commit('LOADING', true);
    const response = await request.put({
        url: 'dragon',
        data
    });
    if (response.data) {
        commit('UPDATE_CHANNEL', response.data.data.objCoChannel);
        commit('LOADING', false);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }
};

const resetResult = async ({commit}) => {
   
    const response = await request.get({
        url: 'dragon/resetResult'
    });
    if (response.data.code) {
        let objSummaryCoResult = {
            D: 0,
            T: 0,
            TIE: 0,
            CANCEL: 0
        }
        commit('UPDATE_CHANNEL', response.data.data.objCoChannel);
        commit('SUMMARY_RESULT', objSummaryCoResult);
        commit('RESULT_TABLE', []);

    }
};
const resetShoe = async ({commit}) => {
  
    const response = await request.get({
        url: 'dragon/resetShoe'
    });

    if (response.data.code) {
        let objSummaryCoResult = {
            P: 0,
            B: 0,
            T: 0,
            PB: 0,
            PP: 0
        }
        commit('UPDATE_CHANNEL', response.data.data.objCoChannel);
        commit('SUMMARY_RESULT', objSummaryCoResult);
        commit('RESULT_TABLE', []);

    }
};

const _fetchLastResult = async ({commit}, data) => {
    const response = await request.post({
        url: 'dragon/get-result',
        data
    });
    if (response.data.code) {   
        commit('GET_LAST_RESULT', response.data.data.objectLastResult);
    }
};


const _fetchCheckColumExist = async ({commit}, data) => {
    const response = await request.post({
        url: 'dragon/check-existed-column',
        data
    });
    if (response.data.code) {   
        commit('GET_SKIP_COLUMN', response.data.data.objectLastResult);
    }
};

const _fetchCheckRowExist = async ({commit}, data) => {
    const response = await request.post({
        url: 'dragon/check-existed-row',
        data
    });
    if (response.data.code) {   
        commit('GET_SKIP_ROW', response.data.data.objectLastResult);
    }
};

const _fetchCheckBetweenExist = async ({commit}, data) => {
    const response = await request.post({
        url: 'dragon/check-existed-between',
        data
    });
    if (response.data.code) {   
        commit('GET_SKIP_BETWEEN', response.data.data.objectNoResultBetween);
    }
};

const _popUpDialogResult =  ({commit}, payload) => {
    commit('POPUP_DIALOG_RESULT', payload);
};

const _fetchGetDealerInfo = async ({ commit },  { vm, data }) => {
    const response = await request.post({
        url: 'dealer/scanBarcode',
        data
    });
    if (response.data.code) {
        Vue.$cookies.set("dealerId", data , 0);
        commit('UPDATE_DEALER_INFO', response.data.data);
        commit('POPUP_DIALOG_SCAN_DEALER', false);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    }
    else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }
};

const submitCardCode = async ({ commit },  { vm, data }) => {
    commit("ALLOW_SCAN", true);
    let response = await request.post({
        url: 'dragon/scanCard',
        data
    });
    if (response.data.code) {
        if(response.data.data.objCoChannel) {
            commit('RESULT_EVENT', false);
            commit('UPDATE_CHANNEL', response.data.data.objCoChannel);
            commit('SUMMARY_RESULT', response.data.data.objSummaryCoResult);
            commit('RESULT_TABLE', response.data.data.objCoResult);
            vm.$toastr.s(`${response.data.message.descriptions}`);
        }
    }else{
        vm.$toastr.e(`${response.data.message.descriptions}`);
    }
    commit("ALLOW_SCAN", false);
};
const UpdateFinishedCard = async ({ commit }, { vm, data }) => {
    // commit('LOADINGRESULT', true);
    const response = await request.post({
        url: 'dragon/finishedCard',
        data
    });
    if (response.data.code) {
        commit('UPDATE_CHANNEL', response.data.data.objCoChannel);
        
        if(response.data.data.objSummaryCoResult && response.data.data.objCoResult){
            commit('SUMMARY_RESULT', response.data.data.objSummaryCoResult);
            commit('RESULT_TABLE', response.data.data.objCoResult);
        }
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }
};

const _popUpDialogDealer =  ({commit}, payload) => {
    commit('POPUP_DIALOG_SCAN_DEALER', payload);
};
const voidShoe = async ({ commit }, { data }) => {
    commit('LOADINGRESULT', true);
    const response = await request.post({
        url: 'dragon/voidShoe',
        data
    });
    if (response.data.code) {
        let objSummaryCoResult = {
            D: 0,
            T: 0,
            TIE: 0
        }
        commit('UPDATE_CHANNEL', response.data.data.objCoChannel);
        commit('SUMMARY_RESULT', objSummaryCoResult);
        commit('RESULT_TABLE', []);
    }

};
const resetCard =  ({commit}) => {
    commit('RESULT_EVENT', true);
};
export default {
    fetchCurrentChannel,
    fetchBtnResult,
    createResult,
    updateChannel,
    UpdateChanelStatus,
    UpdateFinishedCard,
    UpdateClearCard,
    resetResult,
    voidShoe,
    resetShoe,
    _fetchLastResult,
    _popUpDialogResult,
    _fetchCheckColumExist,
    _fetchCheckRowExist,
    _fetchCheckBetweenExist,
    _fetchGetDealerInfo,
    submitCardCode,
    _popUpDialogDealer,
    fetchCurrentCard,
    resetCard,
    fetchCurrentTotal
};