<template>
    <v-flex class="form-section-container">
        <div id="wrapper">
            <div class="auth-page login-wrapper">
                <div class="login-panel position-relative pt-5">
                    <div v-if="isOTPShow == true" class="otp-layout">
                        <div class="otp-label">INPUT OTP CODE</div>
                        <v-otp-input class="justify-content-center" ref="otpInput" inputClasses="otp-input"
                            :numInputs="6" :is-input-num="true" separator="-" :shouldAutoFocus="true"
                            @on-complete="handleOnComplete" @on-change="handleOnChange" />
                        <div v-if="isOTPError" class="otp-error">{{ otpErrorMgs }}</div>
                    </div>
                    <div v-else-if="isOTPShow == false">
                        <div class="card card-primary">
                            <div class="card-body">
                                <v-form ref="form" lazy-validation @submit.prevent="login">
                                    <div style="width:100%; display: flex; justify-content: center;">
                                        <img src="/images/logo.png" 
                                        class="my-2 mx-auto justify-center align-center" 
                                        style="width:70px; height:70px"/>
                                    </div>
                                    
                                    <hr class="my-5 bg-gray-400 opacity-50" />
                                    <div v-if="error" class="mb-4 text-danger text-center">
                                        {{ description }}
                                    </div>
                                
                                        <div class="form-group">
                      <label>User name</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <div class="input-group-text">
                            <i class="fas fa-user"></i>
                          </div>
                        </div>
                        <input type="text" class="form-control phone-number" v-model="user.user_name" label="Username" :rules="rules" required
                                        placeholder="Enter your username" @input="error = false">
                      </div>
                    </div>
                                    <div class="form-group">
                                        <label>Password Strength</label>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <div class="input-group-text">
                                                    <i class="fas fa-lock"></i>
                                                </div>
                                            </div>
                                            <input type="password" class="form-control pwstrength"
                                                data-indicator="pwindicator" v-model="user.password"  label="Password" :rules="rules"
                                        required  placeholder="Enter your password"
                                        @input="error = false">
                                        </div>
                                        <div id="pwindicator" class="pwindicator">
                                            <div class="bar"></div>
                                            <div class="label"></div>
                                        </div>
                                    </div>
                                    <div class="flex justify-end mt-3">
                                        <v-btn :loading="loading" type="submit" :disabled="loading" color="primary"
                                            style="background-color: #6975E2; width: 100%; margin: 0 !important;">
                                            Login
                                        </v-btn>
                                    </div>
                                </v-form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </v-flex>
</template>

<script>
import { mapActions } from "vuex";
export default {
    data: () => ({
        user: {
            user_name: null,
            password: null,
            app_id: null
        },
        rules: [(value) => !!value || "Required."],
        loading: false,
        error: false,
        isOTPShow: false,
        tmpChannelUserId: null,
        show: false,
        isOTPError: false,
        otpErrorMgs: "Wrong OTP",
        tempChannelUser: null,
        description: ""
    }),
    methods: {
        reset() {
            this.$refs.form.reset();
        },
        async handleOnComplete(value) {
            try {
                let otpData = {
                    user_id: this.tmpChannelUserId,
                    otp: value
                }
                const response = await this.fetchVerifyOtp(otpData)
                if (response.data.code) {
                    //success
                    let rolee = this.tempChannelUser.role
                    this.$cookies.set("token", response.data.data.token);
                    this.$cookies.set("channel-type", 1);
                    this.$cookies.set("user_role", rolee);
                    this.$cookies.set("local", 'kh');
                    this.$cookies.set("user", this.tempChannelUser);
                    this.$router.push({ path: "/dashboard" });
                } else {
                    this.$refs.otpInput.clearInput();
                    this.otpErrorMgs = response.data.message.descriptions;
                    this.isOTPError = true;
                }

            } catch (error) {
                this.$refs.otpInput.clearInput();
                this.otpErrorMgs = error.message;
                this.isOTPError = true;
            }
        },
        handleOnChange() {
            this.isOTPError = false;
        },
        async login() {
            if (this.$refs.form.validate()) {
                try {
                    this.loading = true;
                    this.user.app_id = process.env.VUE_APP_APP_ID;
                    const response = await this.fetchLogIn(this.user);
                    if (response.data.code
                        //condition dnt let user role player login in to admin system
                        && response.data.data.user.role != "61829df5e64b1b1768832700"
                        && response.data.data.user.role != "61829dfee64b1b1768832709"
                        && response.data.data.user.role != "61829e05e64b1b1768832712") {
                        const { token, uuid, user } = response.data.data;

                        if (token == "") {
                            this.$cookies.set("uuid", uuid);
                            this.tempChannelUser = user
                            this.tmpChannelUserId = user._id
                            this.isOTPShow = true
                        } else {
                            let rolee = user.role
                            this.$cookies.set("token", token);
                            this.$cookies.set("user_role", rolee);
                            this.$cookies.set("channel-type", 1);
                            this.$cookies.set("local", 'kh');
                            this.$cookies.set("user", user);
                            if(response.data.data.user.role == "6191dc644825e6f57ffa231f" || response.data.data.user.role == "6191dce94825e65937fa2340" || response.data.data.user.role == "6424080f50b77c6cf890ab11" || response.data.data.user.role == "6472bfce1ba4b8a1604940c2"){
                                this.$router.push({ path: "/userSubAgent" });
                            }else{
                                this.$router.push({ path: "/users" });
                            }
                        }

                    } else {
                        this.error = true;
                        this.description = response.data.message.descriptions;
                    }
                } catch (error) {
                    this.error = true;
                    return error;
                } finally {
                    this.loading = false;
                }
            }
        },
        ...mapActions("$_auth", ["fetchLogIn", "fetchVerifyOtp"]),
    },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&family=Siemreap&display=swap');
</style>

<style lang="scss" >
.siepream {
    font-family: 'Roboto', 'Siemreap', cursive, sans-serif;

}

.info {
    color: #28546C;
    font-weight: bold;
    padding-left: 25px;
}

.info-top {
    padding-top: 10px
}

.otp-layout {
    border: 1px solid hsla(0, 0%, 100%, 0.05);
    border-radius: 4px;
    padding: 20px;
    margin: auto;
    background-color: white;
    width: fit-content;
    height: fit-content;
}

.otp-label {
    color: black;
    text-shadow: red;
    font-size: 18px;
    text-align: center;
    margin: 8px;
    margin-bottom: 16px;
}

.otp-error {
    color: #f34141;
    text-shadow: #f8f6f6;
    font-size: 13px;
    text-align: center;
    margin: 8px;
}

.otp-input {
    width: 40px;
    height: 40px;
    padding: 5px;
    margin: 0 10px;
    font-size: 20px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    text-align: center;

    &.error {
        border: 1px solid red !important;
    }
}

.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.m-x-login {
    margin: 0px 20px;
}
</style>